import type { TAuthor } from '@/types/authorType';
import type { TMkpoClass } from '@/types/mkpoTypes';
import { PdfType } from '@/utils/getPdfHistoryOptions';

export enum RegisteredModelTypes {
  'INVENTION' = 1,
  'UTILITY_MODEL' = 2,
  'INDUSTRIAL_MODEL' = 3,
  'TRADEMARK' = 4,
  'PARTICLE' = 5
}

export enum UnitedRmts {
  'INVENTION' = 'ИзПМ',
  'UTILITY_MODEL' = 'ИзПМ',
  'INDUSTRIAL_MODEL' = 'Промышленный образец',
  'TRADEMARK' = 'Товарный знак',
  'PARTICLE' = 'ИЗПМ: молекулы в трехмерном виде'
}

export enum ModelStatus {
  'NEW' = 'NEW',
  'PROCESSING' = 'PROCESSING',
  'CONVERTED' = 'CONVERTED',
  'FAILED' = 'FAILED',
}

export enum ModelFileFormats {
  'STEP' = 1,
  'STP' = 2,
  'OBJ' = 3,
  'STL' = 4,
  'U3D' = 5,
  'PRC' = 6,
  'MOL' = 7,
  'MOL2' = 8,
  'CDX' = 9
}

export type TModelPartList = {
  id: number;
  name: string;
  uuid: string;
  children: TModelPartList[];
}

export type TPdfUnit = {
  createdAt: string;
  modelUuid: string;
  pdfType: PdfType;
  requestedBy: TAuthor;
}

export type TModelData = {
  author?: TAuthor;
  comment?: string;
  conversionPercentage?: number;
  createdAt?: number;
  details?: string;
  fileName?: string;
  mkpoClass?: TMkpoClass;
  lastRequestedPdfHistory?: TPdfUnit[];
  mkpoClassParent?: TMkpoClass;
  mkpoClassGrandParent?: TMkpoClass;
  id?: number;
  modelData?: string;
  modelFileFormat?: {
    format: string;
    id: ModelFileFormats;
  };
  modelUuid?: string;
  modifiedAt?: number;
  patentId?: string | null;
  registeredModelType?: {
    id: RegisteredModelTypes;
    type: string;
  };
  modelPartList?: TModelPartList[];
  requestId?: string | null;
  status?: ModelStatus;
  variantId?: string;
  version?: number;
  totalSteps?: number;
  currentStep?: number;
  currentStepService?: number;
  currentStepStartTimestamp?: number;
}

export type TModelListData = {
  content?: TModelData[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: {
    offset?: number;
    pageNumber?: number;
    pageSize?: number;
    paged?: boolean;
    sort?: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged?: boolean;
  };
  size?: number;
  sort?: {
    empty?: boolean;
    sorted?: boolean;
    unsorted?: boolean;
  };
  totalElements?: number;
  totalPages?: number;
}

export type TAddInfo = {
  name: string;
  text?: string | null;
}
