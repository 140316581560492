import { HttpService } from '@/services/httpService';
import axios from 'axios';
import type { TPaginator } from '@/store/modules/paginator/state';
import type { TSearchFilter } from '@/store/modules/search/state';
import type { TReportUnit } from '@/types/reportUnit';
import type { TReportFilterForBe } from '@/types/reportFilterForBe';

const CancelToken = axios.CancelToken;
let cancel: Function;

export class SearchHttpService {
  private httpService: HttpService = new HttpService();

  async getCompareTaskById(id: string) {
    if (cancel !== undefined) {
      cancel();
    }
    const axiosConfig = {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    };
    return await this.httpService.get(`/compare/task/${id}`, axiosConfig);
  }

  async getCompareResultById(id: string, pag: TPaginator | undefined, params?: TSearchFilter) {
    const filters = params ? `showModelModel=${params.showModelModel}&showModelPart=${params.showModelPart}&showPartModel=${params.showPartModel}&showPartPart=${params.showPartPart}` : '';
    return await this.httpService.get(`/compare/task/${id}/results?size=${pag?.size}&page=${pag?.page}&${filters}`);
  }

  async postCompareTask(params: object) {
    return await this.httpService.post('/compare/task', params);
  }

  async getReportByRequestId(requestId: string) {
    return await this.httpService.get(`/compare/report/request/${requestId}`);
  }

  async getReportUnit(reportId: number, params: TReportFilterForBe, pag: TPaginator | undefined) {
    if (cancel !== undefined) {
      cancel();
    }
    const axiosConfig = {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    };
    return await this.httpService.get(
      `/compare/report/${reportId}/unit?size=${pag?.size}&page=${pag?.page}&sort=id,desc&combinedRequestIdAndPatentId=${params.combinedRequestIdAndPatentId}&endSearchDate=${params.endSearchDate}&startSearchDate=${params.startSearchDate}`,
      axiosConfig
    );
  }

  async postUnitToReport(reportId: string, params: TReportUnit) {
    return await this.httpService.post(`/compare/report/${reportId}/unit`, params);
  }

  async deleteUnitById(reportId: string, unitId: string) {
    return await this.httpService.delete(`/compare/report/${reportId}/unit/${unitId}`);
  }

  async downloadReportFile(reportId: number) {
    return await this.httpService.get(`/compare/report/${reportId}/file`);
  }

  async finishReport(reportId: number) {
    return await this.httpService.post(`/compare/report/${reportId}/finish`);
  }
}
