<template>
  <div class="p-field custom-p-field ui-input-field">
    <label :class="`${!!errorMessage && 'ui-error-label'}`" :for='`uid${name}`'>{{ label }}</label>
    <label
      tabindex="0"
      :class="`${!!errorMessage && 'p-invalid'} p-inputtext p-component ui-file-input`"
    >
      <div class="button">Выбрать файл</div>
      <div class="text">{{ inputValue?.name }}</div>
      <input
        ref="filewRef"
        type='file'
        :id="`uid${name}`"
        :name="name"
        @input="customChange"
        @blur="handleBlur"
      />
    </label>
    <BaseInlineMessage
      v-if="!!errorMessage"
      :message-text="errorMessage"
    />
  </div>
</template>

<script src="./BaseUploadFile.ts" lang="ts"/>
<style src="./BaseUploadFile.scss" lang="scss" scoped/>
