import state, { TPaginatorState } from '@/store/modules/paginator/state';
import mutations from '@/store/modules/paginator/mutations';
import { Module } from 'vuex';
import type { TRootState } from '@/store/state';

const modelsModule: Module<TPaginatorState, TRootState> = {
  state,
  mutations,
  namespaced: true
};

export default modelsModule;
