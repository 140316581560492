import { TOASTER_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import type { TToasterMessage, TToasterState } from '@/store/modules/toaster/state';

const mutations = {
  [TOASTER_MUTATIONS_TYPE.APPEND_MESSAGE](state: TToasterState, payload: TToasterMessage) {
    state.messages.push(payload);
  }
};

export default mutations;
