import { SEARCH_ACTIONS_TYPE } from '@/store/names/action.name';
import { ActionContext } from 'vuex';
import { PAGINATOR_MUTATIONS_TYPE, SEARCH_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import type { TReportFilters, TSearchFilter, TSearchState, TSearchedData, TReportUnitContent } from '@/store/modules/search/state';
import { SearchHttpService } from '@/services/searchService';
import store from '@/store';
import type { TAddUnit, TDeleteUnit } from '@/types/reportUnit';
import { MODULES_NAMES } from '@/store/names/modules.name';
import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';
import { createDownload } from '@/store/modules/models/utils/createDownload';
import type { TReportFilterForBe } from '@/types/reportFilterForBe';

const searchHttpService = new SearchHttpService();

const actions = {
  async [SEARCH_ACTIONS_TYPE.GET_COMPARE_TASK]({ commit }: ActionContext<TSearchState, {}>, payload: string): Promise<void> {
    const { data } = await searchHttpService.getCompareTaskById(payload);
    commit(SEARCH_MUTATIONS_TYPE.SET_COMPARE_TASK, data);
  },

  async [SEARCH_ACTIONS_TYPE.GET_COMPARE_RESULTS]({ commit }: ActionContext<TSearchState, {}>, payload: string): Promise<void> {
    commit(SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_SEARCH, true);
    const pag = store.state.paginator?.paginator;
    const filters = store.state.search?.searchFilter;
    const { data } = await searchHttpService.getCompareResultById(payload, pag, filters);
    commit(SEARCH_MUTATIONS_TYPE.SET_COMPARE_RESULTS, data);
    commit(SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_SEARCH, false);
  },

  [SEARCH_ACTIONS_TYPE.SET_SEARCH_FILTERS]({ commit }: ActionContext<TSearchState, {}>, payload: TSearchFilter): void {
    const pagSize = store.state.paginator?.paginator.size || defaultPaginatorSettings.size;
    store.commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`, {
      size: pagSize,
      page: defaultPaginatorSettings.first
    });
    commit(SEARCH_MUTATIONS_TYPE.SET_SEARCH_FILTERS, payload);
  },

  async [SEARCH_ACTIONS_TYPE.GET_REPORT_DATA]({ commit }: ActionContext<TSearchState, {}>, payload: string): Promise<void> {
    commit(SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_REPORT, true);
    const { data } = await searchHttpService.getReportByRequestId(payload);
    commit(SEARCH_MUTATIONS_TYPE.SET_REPORT_DATA, data);
    commit(SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_REPORT, false);
  },

  async [SEARCH_ACTIONS_TYPE.GET_REPORT_UNITS]({ commit }: ActionContext<TSearchState, {}>, payload: number): Promise<void> {
    commit(SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_REPORT_UNITS, true);
    const pag = store.state.paginator?.paginator;
    const filtersState = store.state.search?.filters;
    const params: TReportFilterForBe = {
      combinedRequestIdAndPatentId: filtersState?.query ? filtersState?.query : '',
      startSearchDate: filtersState?.date ? (filtersState?.date[0]) : '',
      endSearchDate: filtersState?.date ? (filtersState?.date[1]) : ''
    };
    const { data } = await searchHttpService.getReportUnit(payload, params, pag);
    commit(SEARCH_MUTATIONS_TYPE.SET_REPORT_UNITS, data);
    commit(SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_REPORT_UNITS, false);
  },

  async [SEARCH_ACTIONS_TYPE.ADD_UNIT_TO_REPORT]({ commit, state }: ActionContext<TSearchState, {}>, payload: TAddUnit): Promise<void> {
    commit(SEARCH_MUTATIONS_TYPE.SET_IS_POSTING_UNIT, true);
    const { data } = await searchHttpService.postUnitToReport(payload.reportId, payload.params);
    const compareResult = { ...state?.searchResult };
    compareResult.content = compareResult.content?.map((item: TSearchedData) => {
      if (item.id === payload.searchUnitId) {
        item.isAddedToReport = true;
        item.reportUnitId = data.id;
      }
      return item;
    });
    commit(SEARCH_MUTATIONS_TYPE.SET_COMPARE_RESULTS, compareResult);
    commit(SEARCH_MUTATIONS_TYPE.SET_IS_POSTING_UNIT, false);
  },

  async [SEARCH_ACTIONS_TYPE.DELETE_UNIT_FROM_REPORT]({ commit, state, dispatch }: ActionContext<TSearchState, {}>, payload: TDeleteUnit): Promise<void> {
    commit(SEARCH_MUTATIONS_TYPE.SET_IS_POSTING_UNIT, true);
    await searchHttpService.deleteUnitById(payload.reportId.toString(), payload.unitId.toString());
    const newUnits = state.reportUnits;
    const deleteIndex = newUnits?.content?.findIndex((item: TReportUnitContent) => (+item.id === +payload.unitId));
    if (deleteIndex !== -1 && deleteIndex !== undefined) {
      newUnits.content.splice(deleteIndex, 1);
      commit(SEARCH_MUTATIONS_TYPE.SET_REPORT_UNITS, newUnits);
    }
    const compareResult = { ...state?.searchResult };
    compareResult.content = compareResult.content?.map((item: TSearchedData) => {
      if (item.id === payload.searchUnitId) {
        item.isAddedToReport = false;
      }
      return item;
    });
    commit(SEARCH_MUTATIONS_TYPE.SET_COMPARE_RESULTS, compareResult);
    dispatch(SEARCH_ACTIONS_TYPE.GET_REPORT_UNITS, state.reportData.id);
    commit(SEARCH_MUTATIONS_TYPE.SET_IS_POSTING_UNIT, false);
  },

  async [SEARCH_ACTIONS_TYPE.DOWNLOAD_REPORT](_: unknown, { reportId, fileName }: { reportId: number; fileName: string }): Promise<void> {
    const { data } = await searchHttpService.downloadReportFile(reportId);
    createDownload(data, fileName);
  },

  async [SEARCH_ACTIONS_TYPE.FINISH_REPORT]({ commit }: ActionContext<TSearchState, {}>, reportId: number): Promise<void> {
    const { data } = await searchHttpService.finishReport(reportId);
    commit(SEARCH_MUTATIONS_TYPE.SET_REPORT_DATA, data);
  },

  async [SEARCH_ACTIONS_TYPE.SET_REPORT_FILTERS]({ commit }: ActionContext<TSearchState, {}>, filters: TReportFilters): Promise<void> {
    const pagSize = store.state.paginator?.paginator.size || defaultPaginatorSettings.size;
    store.commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`, {
      size: pagSize,
      page: defaultPaginatorSettings.first
    });
    commit(SEARCH_MUTATIONS_TYPE.SET_REPORT_FILTERS, filters);
  }
};

export default actions;
