import { defineComponent } from 'vue';
import Message from 'primevue/message';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'BaseToaster',
  components: {
    Message
  },
  props: {
    lifeTime: {
      type: Number,
      default: 5000
    }
  },
  setup() {
    const store = useStore();

    return {
      store
    };
  }
});
