const defaultPageSize = 30;
// const defaultPageSize = 3;

const defaultPaginatorSettings = {
  size: defaultPageSize,
  selectSize: [defaultPageSize, 36, 48],
  first: 0
};

export default defaultPaginatorSettings;
