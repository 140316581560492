import { defineComponent, onMounted, ref } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';

export default defineComponent({
  name: 'BasePopOver',

  props: {
    appendElementId: {
      type: String
    },
    className: {
      type: String
    }
  },

  components: {
    OverlayPanel
  },

  setup() {
    const BasePopOverRef = ref(null);
    const isMounted = ref(false);

    onMounted(() => {
      isMounted.value = true;
    });

    return {
      isMounted,
      BasePopOverRef
    };
  }
});
