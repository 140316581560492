import { computed, defineComponent, onMounted, ref } from 'vue';
import BaseDropdownMenu from '@/components/BaseComponents/BaseDropdownMenu/BaseDropdownMenu.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import ROUTE_NAMES from '@/router/route.names.json';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { RegisteredModelTypes } from '@/types/modelType';
import { isAdmin, isInternalUser, isLeaderWithAccess } from '@/utils/rolesUtils';

const wrapperClass = 'filter-btn-wrapper';

export default defineComponent({
  name: 'FilterButton',
  components: {
    BaseDropdownMenu,
    BaseButton
  },
  setup() {
    const dropdownRef = ref();
    const route = useRoute();

    const toggleDropdown = () => {
      dropdownRef.value.isVisible ? dropdownRef.value.setInVisible() : dropdownRef.value.setVisible();
    };

    const isOpened = computed(() => {
      return dropdownRef.value?.isVisible;
    });

    onMounted(() => {
      const elem = document.querySelector(`.${wrapperClass}`);
      const outsideClickListener = (e: any) => {
        if (!elem?.contains(e.target)) {
          dropdownRef.value && dropdownRef.value.setInVisible();
        }
      };
      document.addEventListener('click', outsideClickListener);
    });

    const isActivePage = (type: string): boolean => {
      return route.name === type;
    };

    const { state } = useStore();

    const isRendering = (rmts: RegisteredModelTypes[]): boolean => {
      return (isInternalUser(state.users.user) || isAdmin(state.users.user) || isLeaderWithAccess(state.users.user, rmts));
    };

    return {
      RegisteredModelTypes,
      wrapperClass,
      ROUTE_NAMES,
      isActivePage,
      isOpened,
      dropdownRef,
      toggleDropdown,
      isRendering
    };
  }
});
