import { RouteRecordRaw } from 'vue-router';
import ROUTE_NAMES from '@/router/route.names.json';
import store from '@/store';
import { redirectToMainPage, redirectToPermittedList, statisticGuard } from '@/router/rolesRouterGuards';
import { RegisteredModelTypes } from '@/types/modelType';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/components/MainPage/MainPage.vue'),
    beforeEnter: (_to, _from, next) => {
      redirectToMainPage(
        store.state.users?.user!,
        next
      );
    }
  },
  {
    path: '/requests',
    name: ROUTE_NAMES.PAGE_REQUESTS,
    component: () => import('@/components/MainPage/MainPage.vue')
  },
  {
    path: '/trademarks',
    name: ROUTE_NAMES.PAGE_TRADEMARK_LIST,
    component: () => import('@/components/Model/PageTrademarkList/PageTrademarkList.vue'),
    beforeEnter: (_to, _from, next) => {
      redirectToPermittedList(
        store.state.users?.user!,
        [RegisteredModelTypes.TRADEMARK],
        next
      );
    }
  },
  {
    path: '/inventions',
    name: ROUTE_NAMES.PAGE_INVENTION_LIST,
    component: () => import('@/components/Model/PageInventionList/PageInventionList.vue'),
    beforeEnter: (_to, _from, next) => {
      redirectToPermittedList(
        store.state.users?.user!,
        [RegisteredModelTypes.INVENTION, RegisteredModelTypes.UTILITY_MODEL],
        next
      );
    }
  },
  {
    path: '/industrials',
    name: ROUTE_NAMES.PAGE_INDUSTRIAL_LIST,
    component: () => import('@/components/Model/PageIndustrialList/PageIndustrialList.vue'),
    beforeEnter: (_to, _from, next) => {
      redirectToPermittedList(
        store.state.users?.user!,
        [RegisteredModelTypes.INDUSTRIAL_MODEL],
        next
      );
    }
  },
  {
    path: '/particles',
    name: ROUTE_NAMES.PAGE_PARTICLES_LIST,
    component: () => import('@/components/Model/PageParticlesList/PageParticlesList.vue'),
    beforeEnter: (_to, _from, next) => {
      redirectToPermittedList(
        store.state.users?.user!,
        [RegisteredModelTypes.PARTICLE],
        next
      );
    }
  },
  {
    path: '/model/:id',
    name: ROUTE_NAMES.PAGE_MODEL_VIEW,
    component: () => import('@/components/Model/PageModelView/PageModelView.vue')
  },
  {
    path: '/search/:id',
    name: ROUTE_NAMES.PAGE_SEARCH,
    component: () => import('@/components/Search/PageSearch/PageSearch.vue')
  },
  {
    path: '/request/:id',
    name: ROUTE_NAMES.PAGE_REQUEST,
    component: () => import('@/components/PageRequest/PageRequest.vue')
  },
  {
    path: '/requhdlink/:id',
    name: ROUTE_NAMES.EXTERNAL_REQUEST,
    component: () => import('@/components/PageRequest/ExternalRequestRedirect/ExternalRequestRedirect.vue')
  },
  {
    path: '/statistics',
    name: ROUTE_NAMES.PAGE_STATISTICS,
    component: () => import('@/components/Statistics/PageStatistics/PageStatistics.vue'),
    beforeEnter: (_to, _from, next) => {
      statisticGuard(
        store.state.users?.user!,
        next
      );
    }
  },
  {
    path: '/neural',
    name: ROUTE_NAMES.PAGE_NEURAL,
    component: () => import('@/components/Neural/PageNeural/PageNeural.vue'),
    beforeEnter: (_to, _from, next) => {
      statisticGuard(
        store.state.users?.user!,
        next
      );
    }
  },
  {
    path: '/logout',
    name: ROUTE_NAMES.PAGE_LOGOUT,
    component: () => import('@/components/LogOutPage/LogOutPage.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  },
  {
    path: '/404',
    name: ROUTE_NAMES.PAGE_NOT_FOUND,
    component: () => import('@/components/PageNotFound/PageNotFound.vue')
  }
];

export default routes;
