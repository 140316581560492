import { NeuralHttpService } from '@/services/neuralService';
import { NEURAL_ACTIONS_TYPE } from '@/store/names/action.name';
import { ActionContext } from 'vuex';
import { NEURAL_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import store from '@/store';
import { cloneDeep } from 'lodash';
import { TNeuralState, TSaveDataset, TSaveNetworkParams, TTrainNetworkParams } from '@/store/modules/neural/types';
import { switchError } from '@/services/utils/switchError';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';

const neuralHttpService = new NeuralHttpService();

const actions = {
  async [NEURAL_ACTIONS_TYPE.GET_NEURAL_LIST]({ commit }: ActionContext<TNeuralState, {}>): Promise<void> {
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL, true);
    const pag = cloneDeep(store.state.paginator?.paginator);
    const { data } = await neuralHttpService.getNeuralList(pag);
    commit(NEURAL_MUTATIONS_TYPE.SET_NEURAL_LIST, data);
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL, false);
  },

  async [NEURAL_ACTIONS_TYPE.SAVE_NEW_NEURAL]({ commit }: ActionContext<TNeuralState, {}>, payload: TSaveNetworkParams): Promise<void> {
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL, true);
    await neuralHttpService.saveNewNeural(payload);
    const pag = cloneDeep(store.state.paginator?.paginator);
    const { data } = await neuralHttpService.getNeuralList(pag);
    commit(NEURAL_MUTATIONS_TYPE.SET_NEURAL_LIST, data);
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL, false);
  },

  async [NEURAL_ACTIONS_TYPE.DELETE_NEURAL]({ commit, dispatch }: ActionContext<TNeuralState, {}>, payload: number): Promise<void> {
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL, true);
    await neuralHttpService.deleteNeural(payload);
    const pag = store.state.paginator?.paginator;
    const { data } = await neuralHttpService.getNeuralList(pag);
    dispatch(`${NEURAL_ACTIONS_TYPE.GET_SHORTS_LISTS}`);
    commit(NEURAL_MUTATIONS_TYPE.SET_NEURAL_LIST, data);
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL, false);
  },

  async [NEURAL_ACTIONS_TYPE.ACTIVATE_NEURAL]({ commit, dispatch }: ActionContext<TNeuralState, {}>, payload: number): Promise<void> {
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL, true);
    try {
      await neuralHttpService.activateNeural(payload);
    } catch (e) {
      switchError(e, FORM_DICTIONARY.ACTIVATE_EXIST);
    } finally {
      const pag = store.state.paginator?.paginator;
      const { data } = await neuralHttpService.getNeuralList(pag);
      dispatch(`${NEURAL_ACTIONS_TYPE.GET_SHORTS_LISTS}`);
      await dispatch(`${NEURAL_ACTIONS_TYPE.GET_ACTIVE_NEURAL}`);
      commit(NEURAL_MUTATIONS_TYPE.SET_NEURAL_LIST, data);
      commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL, false);
    }
  },

  async [NEURAL_ACTIONS_TYPE.TRAIN_NEURAL]({ commit, dispatch }: ActionContext<TNeuralState, {}>, payload: TTrainNetworkParams): Promise<void> {
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL, true);
    await neuralHttpService.trainNeural(payload);
    const pag = store.state.paginator?.paginator;
    const { data } = await neuralHttpService.getNeuralList(pag);
    dispatch(`${NEURAL_ACTIONS_TYPE.GET_SHORTS_LISTS}`);
    commit(NEURAL_MUTATIONS_TYPE.SET_NEURAL_LIST, data);
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL, false);
  },

  async [NEURAL_ACTIONS_TYPE.GET_DATASET_LIST]({ commit }: ActionContext<TNeuralState, {}>): Promise<void> {
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_DATASET, true);
    const pag = store.state.paginator?.paginator;
    const { data } = await neuralHttpService.getDatasetList(pag);
    commit(NEURAL_MUTATIONS_TYPE.SET_DATASET_LIST, data);
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_DATASET, false);
  },

  async [NEURAL_ACTIONS_TYPE.SAVE_NEW_DATASET]({ commit }: ActionContext<TNeuralState, {}>, payload: TSaveDataset): Promise<void> {
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_DATASET, true);
    await neuralHttpService.saveNewDataset(payload.file, payload.name);
    const pag = store.state.paginator?.paginator;
    const { data } = await neuralHttpService.getDatasetList(pag);
    commit(NEURAL_MUTATIONS_TYPE.SET_DATASET_LIST, data);
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_DATASET, false);
  },

  async [NEURAL_ACTIONS_TYPE.DELETE_DATASET]({ commit }: ActionContext<TNeuralState, {}>, payload: number): Promise<void> {
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_DATASET, true);
    await neuralHttpService.deleteDataset(payload);
    const pag = store.state.paginator?.paginator;
    const { data } = await neuralHttpService.getDatasetList(pag);
    commit(NEURAL_MUTATIONS_TYPE.SET_DATASET_LIST, data);
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_DATASET, false);
  },

  async [NEURAL_ACTIONS_TYPE.GET_SHORTS_LISTS]({ commit }: ActionContext<TNeuralState, {}>): Promise<void> {
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_SHORT, true);
    const { data } = await neuralHttpService.getShortNeuralList();
    const { data: datasets } = await neuralHttpService.getShortDatasetList();
    commit(NEURAL_MUTATIONS_TYPE.SET_SHORT_NEURAL_LIST, data);
    commit(NEURAL_MUTATIONS_TYPE.SET_SHORT_DATASET_LIST, datasets);
    commit(NEURAL_MUTATIONS_TYPE.SET_LOADING_SHORT, false);
  },

  async [NEURAL_ACTIONS_TYPE.GET_ACTIVE_NEURAL]({ commit }: ActionContext<TNeuralState, {}>): Promise<void> {
    commit(NEURAL_MUTATIONS_TYPE.SET_IS_LOADING_ACTIVE, true);
    const { data } = await neuralHttpService.getActiveNeural();
    commit(NEURAL_MUTATIONS_TYPE.SET_ACTIVE_NEURAL_OPTIONS, data);
    commit(NEURAL_MUTATIONS_TYPE.SET_IS_LOADING_ACTIVE, false);
  }
};

export default actions;
