import { RegisteredModelTypes } from '@/types/modelType';

type ModelType = {
  invention: {value: number; label: string};
  utilityModel: {value: number; label: string};
  industrialDesign: {value: number; label: string};
  trademark: {value: number; label: string};
  particle: {value: number; label: string};
}

export const MODEL_TYPE: ModelType = {
  invention: { value: RegisteredModelTypes.INVENTION, label: 'Изобретение' },
  utilityModel: { value: RegisteredModelTypes.UTILITY_MODEL, label: 'Полезная модель' },
  industrialDesign: { value: RegisteredModelTypes.INDUSTRIAL_MODEL, label: 'Промышленный образец' },
  trademark: { value: RegisteredModelTypes.TRADEMARK, label: 'Товарный знак' },
  particle: { value: RegisteredModelTypes.PARTICLE, label: 'ИЗПМ: молекулы в трехмерном виде' }
};

export const MODEL_TYPE_SMALL: ModelType = {
  invention: { value: RegisteredModelTypes.INVENTION, label: 'ИЗ' },
  utilityModel: { value: RegisteredModelTypes.UTILITY_MODEL, label: 'ПМ' },
  industrialDesign: { value: RegisteredModelTypes.INDUSTRIAL_MODEL, label: 'ПО' },
  trademark: { value: RegisteredModelTypes.TRADEMARK, label: 'ТЗ' },
  particle: { value: RegisteredModelTypes.PARTICLE, label: 'МОЛ' }
};
