<template>
  <div class="ui-message-wrapper">
    <Message
      class="ui-message"
      :sticky="false"
      :severity="item.severity"
      :life="lifeTime"
      v-for="(item, index) in store.state.toaster.messages"
      :key="index"
    >
      {{ item.content }}
    </Message>
  </div>
</template>

<script src="./BaseToaster.ts" lang="ts"/>
<style src="./BaseToaster.scss" lang="scss"/>
