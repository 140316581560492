import type { TModelsState } from '@/store/modules/models/state';
import type { TUsersState } from '@/store/modules/users/state';
import type { TToasterState } from '@/store/modules/toaster/state';
import type { TPaginatorState } from '@/store/modules/paginator/state';
import type { TSearchState } from '@/store/modules/search/state';
import type { TRequestState } from '@/store/modules/request/state';
import type { TStatisticState } from '@/store/modules/statistic/state';
import type { TNeuralState } from '@/store/modules/neural/types';

export type TRootState = {
  version: string;
  models?: TModelsState;
  users?: TUsersState;
  toaster?: TToasterState;
  paginator?: TPaginatorState;
  search?: TSearchState;
  request?: TRequestState;
  statistic?: TStatisticState;
  neural?: TNeuralState;
}

const state: TRootState = {
  version: ''
};

export default state;
