import state, { TToasterState } from '@/store/modules/toaster/state';
import actions from '@/store/modules/toaster/actions';
import mutations from '@/store/modules/toaster/mutations';
import { Module } from 'vuex';
import type { TRootState } from '@/store/state';

const toasterModule: Module<TToasterState, TRootState> = {
  state,
  actions,
  mutations,
  namespaced: true
};

export default toasterModule;
