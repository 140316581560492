import { StatisticHttpService } from '@/services/statisticService';
import { STATISTICS_ACTIONS_TYPE } from '@/store/names/action.name';
import { ActionContext } from 'vuex';
import { STATISTICS_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { RequestStatus, TStatisticState } from '@/store/modules/statistic/state';
import store from '@/store';
import { RegisteredModelTypes } from '@/types/modelType';

const statisticHttpService = new StatisticHttpService();

const actions = {
  async [STATISTICS_ACTIONS_TYPE.GET_ALL_STATISTICS]({ commit, state }: ActionContext<TStatisticState, {}>, payload: RegisteredModelTypes[]): Promise<void> {
    commit(STATISTICS_MUTATIONS_TYPE.SET_IS_LOADING_ALL_STATISTICS, true);
    const isUtility = payload?.includes(RegisteredModelTypes.UTILITY_MODEL);
    const isInvention = payload?.includes(RegisteredModelTypes.INVENTION);

    if (!isInvention && isUtility) {
      payload.push(RegisteredModelTypes.INVENTION);
    }

    if (isInvention && !isUtility) {
      payload.push(RegisteredModelTypes.UTILITY_MODEL);
    }

    const { data } = await statisticHttpService.getStatistic(payload, state.statisticFilters.dates);
    commit(STATISTICS_MUTATIONS_TYPE.SET_ALL_STATISTICS, data);
    commit(STATISTICS_MUTATIONS_TYPE.SET_IS_LOADING_ALL_STATISTICS, false);
  },

  async [STATISTICS_ACTIONS_TYPE.GET_STATISTICS_PAGE]({ commit, state }: ActionContext<TStatisticState, {}>): Promise<void> {
    const pag = store.state.paginator?.paginator;
    const params: any = {
      query: state.filters.query,
      type: state.filters.type.length ? state.filters.type : [
        RegisteredModelTypes.PARTICLE,
        RegisteredModelTypes.INVENTION,
        RegisteredModelTypes.INDUSTRIAL_MODEL,
        RegisteredModelTypes.TRADEMARK,
        RegisteredModelTypes.UTILITY_MODEL
      ]
    };
    if (state.filters.status && state.filters.status !== RequestStatus.ALL) {
      params.status = state.filters.status;
    }
    const isUtility = params.type.includes(RegisteredModelTypes.UTILITY_MODEL);
    const isInvention = params.type.includes(RegisteredModelTypes.INVENTION);

    // TODO
    if (!isInvention && isUtility) {
      params.type.push(RegisteredModelTypes.INVENTION);
    }

    if (isInvention && !isUtility) {
      params.type.push(RegisteredModelTypes.UTILITY_MODEL);
    }

    commit(STATISTICS_MUTATIONS_TYPE.SET_IS_LOADING, true);
    const { data } = await statisticHttpService.getStatisticsPage(pag, params);
    commit(STATISTICS_MUTATIONS_TYPE.SET_PAGE_STATISTICS, data);
    commit(STATISTICS_MUTATIONS_TYPE.SET_IS_LOADING, false);
  }
};

export default actions;
