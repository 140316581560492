import type { TModelListData, TModelData } from '@/types/modelType';
import type { TFilters, TModelsState, TScreenshots, TMeshState } from '@/store/modules/models/state';
import { MODELS_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { defaultFiltersValue, TModelSearchHistory } from '@/store/modules/models/state';
import { setMapMeshState } from '@/components/Model/utils/setMeshState';

type TInitFilters = {
  filters: TFilters;
}

const mutations = {
  [MODELS_MUTATIONS_TYPE.SET_MODELS_LIST](state: TModelsState, payload: TModelListData) {
    state.modelsData = payload;
  },

  [MODELS_MUTATIONS_TYPE.SET_MODEL_INFO](state: TModelsState, payload: TModelData) {
    state.modelInfo = payload;
  },

  [MODELS_MUTATIONS_TYPE.SET_IS_LOADING_LIST](state: TModelsState, payload: boolean) {
    state.isLoadingList = payload;
  },

  [MODELS_MUTATIONS_TYPE.SET_IS_LOADING_MODEL](state: TModelsState, payload: boolean) {
    state.isLoadingModel = payload;
  },

  [MODELS_MUTATIONS_TYPE.SET_FILTERS](state: TModelsState, payload: TFilters) {
    state.filters = payload;
  },

  [MODELS_MUTATIONS_TYPE.SET_INITIAL_FILTERS](state: TModelsState, payload: TInitFilters) {
    state.filters = payload.filters;
  },

  [MODELS_MUTATIONS_TYPE.RESET_FILTERS](state: TModelsState) {
    state.filters = defaultFiltersValue;
  },

  [MODELS_MUTATIONS_TYPE.SET_MODEL_SCREENSHOTS](state: TModelsState, payload: TScreenshots[]) {
    state.modelScreenShots = payload;
  },

  [MODELS_MUTATIONS_TYPE.SET_MESH_STATE](state: TModelsState, payload: TMeshState[]) {
    state.meshState = payload;
  },

  [MODELS_MUTATIONS_TYPE.SET_MAP_MESH_STATE](state: TModelsState, payload: TMeshState[]) {
    state.mapMeshState = payload;
  },

  [MODELS_MUTATIONS_TYPE.SET_SEARCH](state: TModelsState, payload: string) {
    state.searchMesh = payload;
    state.mapMeshState = setMapMeshState(state.meshState, state.searchMesh);
  },

  [MODELS_MUTATIONS_TYPE.SET_MODEL_SEARCH_HISTORY](state: TModelsState, payload: TModelSearchHistory) {
    state.modelSearchHistory = payload;
  }
};

export default mutations;
