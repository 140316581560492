export const ROOT_MUTATIONS_TYPE = {
  SET_APP_VERSION: 'setAppVersion'
};

export const MODELS_MUTATIONS_TYPE = {
  SET_SEARCH: 'setSearch',
  SET_MAP_MESH_STATE: 'setMapMeshState',
  SET_MODELS_LIST: 'setModelsList',
  SET_MODEL_INFO: 'setModelInfo',
  SET_IS_LOADING_LIST: 'setIsLoadingList',
  SET_FILTERS: 'setFilters',
  SET_INITIAL_FILTERS: 'setInitialFilters',
  SET_IS_LOADING_MODEL: 'setIsLoadingModel',
  SET_MODEL_SCREENSHOTS: 'setModelScreenshot',
  SET_MESH_STATE: 'setMeshState',
  RESET_FILTERS: 'resetFilters',
  SET_MODEL_SEARCH_HISTORY: 'setModelSearchHistory'
};

export const USERS_MUTATIONS_TYPE = {
  SET_CURRENT_USER: 'setCurrentUser'
};

export const TOASTER_MUTATIONS_TYPE = {
  APPEND_MESSAGE: 'appendMessage'
};

export const SEARCH_MUTATIONS_TYPE = {
  SET_REPORT_FILTERS: 'setReportFilters',
  SET_COMPARE_TASK: 'setCompareTask',
  SET_IS_LOADING_SEARCH: 'setIsLoadingSearch',
  SET_COMPARE_RESULTS: 'setCompareResults',
  SET_SEARCH_FILTERS: 'setSearchFilters',
  SET_REPORT_DATA: 'setReportData',
  SET_REPORT_UNITS: 'setReportUnits',
  SET_IS_LOADING_REPORT: 'setIsLoadingReport',
  SET_IS_LOADING_REPORT_UNITS: 'setIsLoadingReportUnits',
  SET_IS_POSTING_UNIT: 'setIsPostingUnit'
};

export const PAGINATOR_MUTATIONS_TYPE = {
  SET_SHOW_TYPE: 'setShowType',
  SET_INITIAL_PAGINATOR: 'setInitialPaginator',
  SET_PAGINATOR: 'setPaginator'
};

export const REQUEST_MUTATIONS_TYPE = {
  SET_REQUEST_DATA: 'setRequestData',
  SET_IS_LOADING_REQUEST: 'setIsLoadingRequest'
};

export const STATISTICS_MUTATIONS_TYPE = {
  SET_ALL_STATISTICS: 'setAllStatistics',
  SET_IS_LOADING_ALL_STATISTICS: 'setIsLoadingAllStatistics',
  SET_STATUS_FILTER: 'setStatusFilter',
  SET_TYPE_FILTER: 'setTypeFilter',
  SET_TYPE_STATISTIC: 'setTypeStatistic',
  SET_DATES_BETWEEN_STATISTIC: 'setDatesBetweenStatistic',
  SET_QUERY_FILTER: 'setQueryFilter',
  SET_PAGE_STATISTICS: 'setPageStatistics',
  SET_IS_LOADING: 'setIsLoading'
};

export const NEURAL_MUTATIONS_TYPE = {
  SET_NEURAL_LIST: 'setNeuralList',
  SET_DATASET_LIST: 'setDatasetList',
  SET_LOADING_NEURAL: 'setLoadingNeural',
  SET_LOADING_DATASET: 'setLoadingDataset',
  SET_SHORT_NEURAL_LIST: 'setShortNeuralList',
  SET_SHORT_DATASET_LIST: 'setShortDatasetList',
  SET_LOADING_SHORT: 'setLoadingShort',
  SET_DATASET_PAGINATOR: 'setPaginator',
  SET_ACTIVE_NEURAL_OPTIONS: 'setActiveNeuralOptions',
  SET_IS_LOADING_ACTIVE: 'setIsLoadingActive'
};
