<template>
  <InlineMessage
    class="ui-inline-message"
    :severity="severity"
  >
    {{ messageText }}
  </InlineMessage>
</template>

<script src="./BaseInlineMessage.ts" lang="ts"/>
<style src="./BaseInlineMessage.scss" lang="scss"/>
