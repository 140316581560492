import type { TMeshState } from '@/store/modules/models/state';

export const setChildrenMesh = (detailList: TMeshState[], boolean: boolean): TMeshState[] => {
  return detailList.map((item: TMeshState) => {
    if (!item.children.length) {
      return {
        ...item,
        isVisible: boolean
      };
    } else {
      return {
        ...item,
        isVisible: boolean,
        children: setChildrenMesh(item.children, boolean)
      };
    }
  });
};

export const findMesh = (id: number | string, detailList: TMeshState[]): TMeshState[] => {
  return detailList.map((item: TMeshState) => {
    if (item.id === id || item.uuid === id) {
      return {
        ...item,
        isVisible: !item.isVisible,
        children: setChildrenMesh(item.children, !item.isVisible)
      };
    } else {
      return {
        ...item,
        children: findMesh(id, item.children)
      };
    }
  });
};

const isAllChildrenInvisible = (item: TMeshState): boolean => {
  if (item.children.length) {
    return item.children.every((childItem: TMeshState) => isAllChildrenInvisible(childItem));
  } else {
    return !item.isVisible;
  }
};

export const watchMeshGroupState = (detailList: TMeshState[]): TMeshState[] => {
  return detailList.map((item: TMeshState) => {
    return {
      ...item,
      isVisible: !isAllChildrenInvisible(item),
      children: watchMeshGroupState(item.children)
    };
  });
};

const isMatched = (search: string, name: string) => {
  return name.toLowerCase().includes(search.toLowerCase());
};

const hasOpenChild = (children: TMeshState[], meshMapState: any) => {
  return children.reduce((memoIsOpen, item) => {
    return (memoIsOpen || meshMapState[item.id].isOpen);
  }, false);
};

export const setMapMeshState = (meshState: TMeshState[], search: string, meshMapState?: any) => {
  meshMapState = meshMapState || {};

  meshState.forEach((item: TMeshState) => {
    setMapMeshState(item.children, search, meshMapState);

    meshMapState[item.id] = {
      ...item,
      isOpen: isMatched(search, item.name) || hasOpenChild(item.children, meshMapState)
    };
  });

  return meshMapState;
};
