import { computed, defineComponent, watch } from 'vue';
import { mixed, number, object, string } from 'yup';
import { useForm } from 'vee-validate';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import { MODEL_FORMAT } from '@/dictionaries/modelData/model.format';
import { MODEL_TYPE } from '@/dictionaries/modelData/model.type';
import BaseForm from '@/components/BaseComponents/BaseForm/BaseForm.vue';
import BaseInputText from '@/components/BaseComponents/BaseInputText/BaseInputText.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BaseUploadFile from '@/components/BaseComponents/BaseUploadFile/BaseUploadFile.vue';
import BaseRadioGroup from '@/components/BaseComponents/BaseRadioGroup/BaseRadioGroup.vue';
import { ModelsHttpService } from '@/services/modelsService';
import appendToastMessage from '@/utils/appendToastMessage';
import { maxFileSizeBytes } from '@/dictionaries/validation/maxFileSizeBytes';
import BaseDropdown from '@/components/BaseComponents/BaseDropdown/BaseDropdown.vue';
import { ModelFileFormats, RegisteredModelTypes } from '@/types/modelType';
import { mkpoTest } from '@/components/Forms/FormUploadModel/utils/mkpoTest';
import MkpoDropdownGroup from '@/components/Forms/MkpoDropdownGroup/MkpoDropdownGroup.vue';
import { fileTest } from '@/components/Forms/FormUploadModel/utils/fileTest';
import { formatTest } from '@/components/Forms/FormUploadModel/utils/formatTest';

const fileInputId = 'validated-file';

export default defineComponent({
  name: 'FormUploadModel',

  components: {
    BaseForm,
    BaseUploadFile,
    BaseInputText,
    BaseButton,
    BaseRadioGroup,
    BaseDropdown,
    MkpoDropdownGroup
  },

  emits: ['closePopup', 'submitted'],

  setup(_, { emit }) {
    const modelsHttpService = new ModelsHttpService();
    const schema = object({
      file: mixed()
        .test('fileSize', FORM_DICTIONARY.LARGE_FILE, value => value?.size <= maxFileSizeBytes)
        .test('fileType', FORM_DICTIONARY.UNACCEPTABLE_FILE_EXTENSION, () => fileTest('uidfile'))
        .required(FORM_DICTIONARY.REQUIRED_FILE),

      fileFormatId: number()
        .test('rightType', FORM_DICTIONARY.NOT_RIGHT_FILE_EXTENSION, value => formatTest('uidfile', value!)),
      registeredModelTypeId: number(),
      patentId: string()
        .max(20, FORM_DICTIONARY.MAX_20_SYMBOL),
      requestId: string()
        .max(20, FORM_DICTIONARY.MAX_20_SYMBOL)
        .required(FORM_DICTIONARY.REQUIRED),
      mkpoClassGrandParent: mixed(),
      mkpoClassParent: mixed()
        .test('isRequired', FORM_DICTIONARY.REQUIRED, (value, parent) => mkpoTest(value, parent)),
      mkpoClassId: mixed()
        .test('isRequired', FORM_DICTIONARY.REQUIRED, (value, parent) => mkpoTest(value, parent))
    });

    const {
      handleSubmit,
      handleReset,
      isSubmitting,
      values,
      setValues
    } = useForm({ validationSchema: schema });

    watch(() => values.file, () => {
      const inputSelector = (document.getElementById('uidfile') as HTMLInputElement);
      const fileName = inputSelector.files?.length ? inputSelector.files[0]?.name?.toUpperCase() : '';
      const type: any = fileName?.split('.')!.pop()!.toUpperCase();
      setValues({ fileFormatId: ModelFileFormats[type] ? +ModelFileFormats[type] : ModelFileFormats.STEP });
    });

    const resetForm = () => {
      handleReset();
      emit('closePopup');
    };

    const changeMkpoValues = (changedClass: Array<keyof typeof values>) => {
      changedClass.forEach((item: keyof typeof values) => {
        values[item] = undefined;
      });
    };

    const submitUploadModelForm = handleSubmit(async(values) => {
      delete values.mkpoClassGrandParent;
      delete values.mkpoClassParent;
      !values.patentId && delete values.patentId;
      !values.requestId && delete values.requestId;
      if (values.mkpoClassId?.id) {
        values.mkpoClassId = values.mkpoClassId?.id;
      } else {
        delete values.mkpoClassId;
      }
      try {
        const data = new FormData();
        Object.entries(values).map(item => {
          data.append(item[0], item[1]);
        });
        await modelsHttpService.uploadModel(data);
        emit('submitted', values);
        resetForm();
      } catch (err) {
        appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
      }
    });

    const isIndustrialModel = computed(() => {
      return (values.registeredModelTypeId === RegisteredModelTypes.INDUSTRIAL_MODEL);
    });

    const modelFormatArray = Object.values(MODEL_FORMAT);
    const modelTypeArray = Object.values(MODEL_TYPE);

    return {
      fileInputId,
      changeMkpoValues,
      isIndustrialModel,
      submitUploadModelForm,
      modelFormatArray,
      modelTypeArray,
      resetForm,
      values,
      isSubmitting
    };
  }
});
