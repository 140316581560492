<template>
  <div :class="wrapperClass">
    <BaseButton
      @clicked="toggleDropdown"
      label="Каталог"
      iconPosition="right"
      :primeIcon="`${isOpened ? 'pi-angle-up' : 'pi-angle-down'}`"
    />
    <BaseDropdownMenu ref="dropdownRef">
      <template v-slot:content>
        <div class="dropdown-filters">
          <router-link
            v-if="isRendering([RegisteredModelTypes.INVENTION, RegisteredModelTypes.UTILITY_MODEL])"
            :class="isActivePage(ROUTE_NAMES.PAGE_INVENTION_LIST) && 'active'"
            :to="{name: ROUTE_NAMES.PAGE_INVENTION_LIST}"
          >
            Изобретение и полезная модель
          </router-link>
          <router-link
            v-if="isRendering([RegisteredModelTypes.INDUSTRIAL_MODEL])"
            :class="isActivePage(ROUTE_NAMES.PAGE_INDUSTRIAL_LIST) && 'active'"
            :to="{name: ROUTE_NAMES.PAGE_INDUSTRIAL_LIST}"
          >
            Промышленный образец
          </router-link>
          <router-link
            v-if="isRendering([RegisteredModelTypes.TRADEMARK])"
            :class="isActivePage(ROUTE_NAMES.PAGE_TRADEMARK_LIST) && 'active'"
            :to="{name: ROUTE_NAMES.PAGE_TRADEMARK_LIST}"
          >
            Товарный знак
          </router-link>
          <router-link
            v-if="isRendering([RegisteredModelTypes.PARTICLE])"
            :class="isActivePage(ROUTE_NAMES.PAGE_PARTICLES_LIST) && 'active'"
            :to="{name: ROUTE_NAMES.PAGE_PARTICLES_LIST}"
          >
            ИЗПМ: молекулы в трехмерном виде
          </router-link>
        </div>
      </template>
    </BaseDropdownMenu>
  </div>
</template>

<script src="./ModelListChangeButton.ts" lang="ts"/>
<style src="./ModelListChangeButton.scss" lang="scss"/>
