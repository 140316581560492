import { RequestHttpService } from '@/services/requestService';
import { REQUEST_ACTIONS_TYPE } from '@/store/names/action.name';
import { ActionContext } from 'vuex';
import { REQUEST_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import type { TRequestState } from '@/store/modules/request/state';

const requestHttpService = new RequestHttpService();

const actions = {
  async [REQUEST_ACTIONS_TYPE.GET_REQUEST_DATA]({ commit }: ActionContext<TRequestState, {}>, payload: string): Promise<void> {
    commit(REQUEST_MUTATIONS_TYPE.SET_IS_LOADING_REQUEST, true);
    const { data } = await requestHttpService.getRequestById(payload);
    commit(REQUEST_MUTATIONS_TYPE.SET_REQUEST_DATA, data);
    commit(REQUEST_MUTATIONS_TYPE.SET_IS_LOADING_REQUEST, false);
  }
};

export default actions;
