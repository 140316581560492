import { HttpService } from '@/services/httpService';
import axios from 'axios';
import type { TPaginator } from '@/store/modules/paginator/state';
import { RegisteredModelTypes } from '@/types/modelType';

const CancelToken = axios.CancelToken;
let cancel: Function;
let cancelPage: Function;

export class StatisticHttpService {
  private httpService: HttpService = new HttpService();

  async getStatistic(types: RegisteredModelTypes[], dates?: [any, any] | null) {
    if (cancel !== undefined) cancel();

    const axiosConfig = {
      cancelToken: new CancelToken(function executor(c) { cancel = c; })
    };

    if (dates?.length) {
      // dates[0] = dates[0].replace(/\+03:00/g, '');
      // dates[0] = dates[0].replace(/T/g, ' ');
      // dates[1] = dates[1].replace(/\+03:00/g, '');
      // dates[1] = dates[1].replace(/T/g, ' ');
      // console.log(dates)
      return await this.httpService.get(
        `/statistics/dates?type=${!types?.length ? [1, 2, 3, 4, 5] : types}&startSearchDate=${dates[0]}&endSearchDate=${dates[1]}`,
        axiosConfig);
    } else {
      return await this.httpService.get(`/statistics?type=${!types?.length ? [1, 2, 3, 4, 5] : types}`, axiosConfig);
    }
  }

  async getStatisticsPage(pag: TPaginator | undefined, params: any) {
    if (cancelPage !== undefined) {
      cancelPage();
    }
    const axiosConfig = {
      cancelToken: new CancelToken(function executor(c) {
        cancelPage = c;
      })
    };
    return await this.httpService.get(
      `/request?registeredModelType=${params.type}&status=${params?.status || ''}&requestId=${params?.query || ''}&size=${pag?.size}&page=${pag?.page}&sort=id,desc`,
      axiosConfig
    );
  }
}
