import store from '@/store';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { TOASTER_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import type { TToasterType } from '@/types/toasterType';

const appendToastMessage = (text: string, type: TToasterType = 'error') => {
  const message = {
    content: text,
    severity: type
  };
  store.commit(`${MODULES_NAMES.TOASTER}/${TOASTER_MUTATIONS_TYPE.APPEND_MESSAGE}`, message);
};

export default appendToastMessage;
