import state, { TRequestState } from '@/store/modules/request/state';
import actions from '@/store/modules/request/actions';
import mutations from '@/store/modules/request/mutations';
import { Module } from 'vuex';
import type { TRootState } from '@/store/state';

const requestModule: Module<TRequestState, TRootState> = {
  state,
  actions,
  mutations,
  namespaced: true
};

export default requestModule;
