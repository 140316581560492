import { HttpService } from '@/services/httpService';

export class RequestHttpService {
  private httpService: HttpService = new HttpService();

  async getRequestById(requestId: string) {
    return await this.httpService.get(`/request/name/${requestId}`);
  }

  async getIdByExternalRequest(externalId: string) {
    return await this.httpService.get(`/request/uhd/${externalId}`);
  }
}
