<template>
  <BaseForm
    :handleSubmit="submitUploadModelForm"
    class="ui-upload-form"
  >
    <template v-slot:form-content>
      <BaseUploadFile
        name="file"
        label="Файл модели"
      />
      <BaseRadioGroup
        name="fileFormatId"
        label="Формат файла"
        :data="modelFormatArray"
      />
      <BaseRadioGroup
        name="registeredModelTypeId"
        label="Тип модели"
        :data="modelTypeArray"
      />
      <BaseInputText
        name="patentId"
        placeholder="RU 3414122 С1"
        type="text"
        label="Номер охранного документа"
      />
      <BaseInputText
        name="requestId"
        placeholder="48236414"
        type="text"
        label="Номер заявки"
      />
      <div v-show="isIndustrialModel">
      <MkpoDropdownGroup
        :values="values"
        @changed="changeMkpoValues"
      />
      </div>
      <div class="split-line"></div>
      <div class="p-d-flex ui-button-group">
        <BaseButton
          :disabled="isSubmitting"
          outlined
          label="Отмена"
          type="reset"
          @clicked="resetForm"
        />
        <BaseButton
          :disabled="isSubmitting"
          label="Загрузить модель"
        />
      </div>
    </template>
  </BaseForm>
</template>

<script src="./FormUploadModel.ts" lang="ts"/>
<style src="./FormUploadModel.scss" lang="scss"/>
