import { SEARCH_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import type {
  TReportFilters,
  TSearchResult,
  TSearchState,
  TSearchFilter,
  TReportUnitsState,
  TReportData
} from '@/store/modules/search/state';
import type { TSearchInfo } from '@/types/serachCompareTypes';

const mutations = {
  [SEARCH_MUTATIONS_TYPE.SET_COMPARE_TASK](state: TSearchState, payload: TSearchInfo) {
    state.searchData = payload;
  },

  [SEARCH_MUTATIONS_TYPE.SET_COMPARE_RESULTS](state: TSearchState, payload: TSearchResult) {
    state.searchResult = payload;
  },

  [SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_SEARCH](state: TSearchState, payload: boolean) {
    state.isLoadingSearch = payload;
  },

  [SEARCH_MUTATIONS_TYPE.SET_SEARCH_FILTERS](state: TSearchState, payload: TSearchFilter) {
    state.searchFilter = payload;
  },

  [SEARCH_MUTATIONS_TYPE.SET_REPORT_DATA](state: TSearchState, payload: TReportData) {
    state.reportData = payload;
  },

  [SEARCH_MUTATIONS_TYPE.SET_REPORT_UNITS](state: TSearchState, payload: TReportUnitsState) {
    state.reportUnits = payload;
  },

  [SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_REPORT](state: TSearchState, payload: boolean) {
    state.isLoadingReport = payload;
  },

  [SEARCH_MUTATIONS_TYPE.SET_REPORT_FILTERS](state: TSearchState, payload: TReportFilters) {
    state.filters = payload;
  },

  [SEARCH_MUTATIONS_TYPE.SET_IS_LOADING_REPORT_UNITS](state: TSearchState, payload: boolean) {
    state.isLoadingReportUnits = payload;
  },

  [SEARCH_MUTATIONS_TYPE.SET_IS_POSTING_UNIT](state: TSearchState, payload: boolean) {
    state.isPostingReportUnit = payload;
  }
};

export default mutations;
