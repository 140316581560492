export const ROOT_ACTIONS_TYPE = {
  FETCH_APP_VERSION: 'fetchAppVersion'
};

export const MODELS_ACTIONS_TYPE = {
  FETCH_MODELS_LIST: 'fetchModelsList',
  FETCH_MODEL_INFO: 'fetchModelInfo',
  DOWNLOAD_3DPDF: 'download3dpdf',
  DOWNLOAD_FILE: 'downloadFile',
  FETCH_MODEL_SCREENSHOTS: 'getModelScreenshots',
  GET_MODEL_SEARCH_HISTORY: 'getModelSearchHistory',
  SET_FILTERS: 'setFilters',
  DELETE_MODEL: 'deleteModel'
};

export const USERS_ACTIONS_TYPE = {
  FETCH_CURRENT_USER: 'fetchCurrentUser',
  FETCH_LOG_IN: 'fetchLogIn',
  LOG_OUT: 'logOut'
};

export const SEARCH_ACTIONS_TYPE = {
  SET_REPORT_FILTERS: 'setReportFilters',
  GET_COMPARE_TASK: 'getCompareTask',
  GET_COMPARE_RESULTS: 'getCompareResults',
  SET_SEARCH_FILTERS: 'setSearchFilters',
  GET_REPORT_DATA: 'getReportData',
  ADD_UNIT_TO_REPORT: 'addUnitToReport',
  GET_REPORT_UNITS: 'getReportUnits',
  DELETE_UNIT_FROM_REPORT: 'deleteUnitFromReport',
  DOWNLOAD_REPORT: 'downloadReport',
  FINISH_REPORT: 'finishReport'
};

export const REQUEST_ACTIONS_TYPE = {
  GET_REQUEST_DATA: 'getRequestData'
};

export const STATISTICS_ACTIONS_TYPE = {
  GET_ALL_STATISTICS: 'getAllStatistics',
  GET_STATISTICS_PAGE: 'getStatisticsPage'
};

export const NEURAL_ACTIONS_TYPE = {
  GET_NEURAL_LIST: 'getNeuralList',
  DELETE_NEURAL: 'deleteNeural',
  TRAIN_NEURAL: 'trainNeural',
  ACTIVATE_NEURAL: 'activateNeural',
  SAVE_NEW_NEURAL: 'saveNewNeural',
  GET_DATASET_LIST: 'getDatasetList',
  SAVE_NEW_DATASET: 'saveNewDataset',
  DELETE_DATASET: 'deleteDataset',
  GET_SHORTS_LISTS: 'getShortsLists',
  GET_ACTIVE_NEURAL: 'getActiveNeural'
};
