import { defineComponent } from 'vue';
import AuthButton from '@/components/NavBar/components/AuthButton/AuthButton.vue';
import UploadButton from '@/components/NavBar/components/UploadButton/UploadButton.vue';
import { useStore } from 'vuex';
import ROUTE_NAMES from '@/router/route.names.json';
import ModelListChangeButton from '@/components/NavBar/components/ModelListChangeButton/ModelListChangeButton.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import { useRouter } from 'vue-router';
import { isAdmin, isExpertOnly, isInternalUser, isInternalAdmin } from '@/utils/rolesUtils';

export default defineComponent({
  name: 'NavBar',

  components: {
    UploadButton,
    AuthButton,
    BaseButton,
    ModelListChangeButton
  },
  setup() {
    const { state } = useStore();
    const { push } = useRouter();

    const pushToRequest = () => {
      push({ name: ROUTE_NAMES.PAGE_REQUESTS });
    };

    return {
      pushToRequest,
      isInternalUser,
      isInternalAdmin,
      state,
      isAdmin,
      isExpertOnly,
      ROUTE_NAMES
    };
  }
});
