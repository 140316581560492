import { ROOT_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import type { TRootState } from '@/store/state';

const mutations = {
  [ROOT_MUTATIONS_TYPE.SET_APP_VERSION](state: TRootState, { version }: { version: string }) {
    state.version = version;
  }
};

export default mutations;
