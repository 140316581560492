import { defineComponent } from 'vue';
import Button from 'primevue/button';

export default defineComponent({
  name: 'BaseButton',

  components: {
    Button
  },

  props: {
    label: {
      type: String
    },
    type: {
      type: String,
      default: 'submit'
    },
    disabled: {
      type: Boolean
    },
    outlined: {
      type: Boolean
    },
    primeIcon: {
      type: String
    },
    iconPosition: {
      type: String
    },
    className: {
      type: String
    }
  },

  setup(_, { emit }) {
    const clickHandler = () => {
      emit('clicked');
    };

    return {
      clickHandler
    };
  }
});
