import { HttpService } from '@/services/httpService';

export class UserHttpService {
  private httpService: HttpService = new HttpService();

  async logIn(options: object) {
    return await this.httpService.post('/login', options);
  }

  async getCurrentUser() {
    return await this.httpService.get('/currentUser');
  }
}
