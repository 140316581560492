<template>
  <div class="p-field-radio ui-input-field p-field" >
    <label :class="`${!!errorMessage && 'ui-error-label' }`">{{ label }}</label>
    <div class="p-field-radio-block">
        <button
          v-for="(item, index) in data"
          @click="handleChange(item.value)"
          :class="`texted-radio-button ${(item.value === value) && 'active'} ${disabled && 'disabled'}`"
          :key="index+item.label"
          type="button"
          :disabled="disabled"
        >
          {{ item.label }}
        </button>
    </div>
    <BaseInlineMessage
      v-show="!!errorMessage"
      :message-text="errorMessage"
    />
  </div>
</template>

<script src="./BaseRadioGroup.ts" lang="ts"/>
<style src="./BaseRadioGroup.scss" lang="scss" scoped/>
