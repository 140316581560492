import state, { TModelsState } from '@/store/modules/models/state';
import actions from '@/store/modules/models/actions';
import mutations from '@/store/modules/models/mutations';
import { Module } from 'vuex';
import type { TRootState } from '@/store/state';

const modelsModule: Module<TModelsState, TRootState> = {
  state,
  actions,
  mutations,
  namespaced: true
};

export default modelsModule;
