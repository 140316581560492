import { PAGINATOR_MUTATIONS_TYPE, STATISTICS_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import type { RequestStatus, TStatisticState } from '@/store/modules/statistic/state';
import { RegisteredModelTypes } from '@/types/modelType';
import store from '@/store';
import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';
import { MODULES_NAMES } from '@/store/names/modules.name';

const mutations = {
  [STATISTICS_MUTATIONS_TYPE.SET_ALL_STATISTICS](state: TStatisticState, payload: any) {
    state.content = payload;
  },

  [STATISTICS_MUTATIONS_TYPE.SET_IS_LOADING_ALL_STATISTICS](state: TStatisticState, payload: boolean) {
    state.isLoading = payload;
  },

  [STATISTICS_MUTATIONS_TYPE.SET_STATUS_FILTER](state: TStatisticState, payload: RequestStatus) {
    const pagSize = store.state.paginator?.paginator.size || defaultPaginatorSettings.size;
    store.commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`, {
      page: defaultPaginatorSettings.first,
      size: pagSize
    });
    state.filters.status = payload;
  },

  [STATISTICS_MUTATIONS_TYPE.SET_TYPE_FILTER](state: TStatisticState, payload: RegisteredModelTypes[]) {
    const pagSize = store.state.paginator?.paginator.size || defaultPaginatorSettings.size;
    store.commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`, {
      page: defaultPaginatorSettings.first,
      size: pagSize
    });
    state.filters.type = payload;
  },

  [STATISTICS_MUTATIONS_TYPE.SET_TYPE_STATISTIC](state: TStatisticState, payload: RegisteredModelTypes[]) {
    state.statisticFilters.type = payload;
  },

  [STATISTICS_MUTATIONS_TYPE.SET_DATES_BETWEEN_STATISTIC](state: TStatisticState, payload: [Date, Date]) {
    state.statisticFilters.dates = payload;
  },

  [STATISTICS_MUTATIONS_TYPE.SET_QUERY_FILTER](state: TStatisticState, payload: string) {
    const pagSize = store.state.paginator?.paginator.size || defaultPaginatorSettings.size;
    store.commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`, {
      page: defaultPaginatorSettings.first,
      size: pagSize
    });
    state.filters.query = payload;
  },

  [STATISTICS_MUTATIONS_TYPE.SET_PAGE_STATISTICS](state: TStatisticState, payload: any) {
    state.statisticsPage = payload;
  },

  [STATISTICS_MUTATIONS_TYPE.SET_IS_LOADING](state: TStatisticState, payload: boolean) {
    state.isLoadingPage = payload;
  }
};

export default mutations;
