import { defineComponent, PropType } from 'vue';
import InlineMessage from 'primevue/inlinemessage';
import type { TToasterType } from '@/types/toasterType';

export default defineComponent({
  name: 'BaseInlineMessage',
  components: {
    InlineMessage
  },
  props: {
    messageText: {
      type: String
    },
    severity: {
      type: String as PropType<TToasterType>,
      default: 'error'
    }
  }
});
