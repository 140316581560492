import { defineComponent, ref } from 'vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BasePopOver from '@/components/BaseComponents/BasePopOver/BasePopOver.vue';
import FormLogin from '@/components/Forms/FormLogin/FormLogin.vue';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { USERS_ACTIONS_TYPE } from '@/store/names/action.name';
import appendToastMessage from '@/utils/appendToastMessage';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import ROUTE_NAMES from '@/router/route.names.json';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'AuthButton',
  components: {
    BaseButton,
    BasePopOver,
    FormLogin
  },
  setup() {
    const loginPopOverRef = ref();
    const hasError = ref(false);
    const {
      state,
      dispatch
    } = useStore();
    const { resolve } = useRouter();

    const setErrorWrapper = () => {
      hasError.value = true;
    };

    const togglePopOver = () => {
      hasError.value = false;
      return loginPopOverRef?.value?.BasePopOverRef.toggle(event);
    };

    const logOut = async() => {
      if (process.env.VUE_APP_HAS_CAS) {
        const routeData = resolve({ name: ROUTE_NAMES.PAGE_LOGOUT });
        window.location.assign(routeData.href);
        await dispatch(`${MODULES_NAMES.USERS}/${USERS_ACTIONS_TYPE.LOG_OUT}`);
      } else {
        await dispatch(`${MODULES_NAMES.USERS}/${USERS_ACTIONS_TYPE.LOG_OUT}`);
      }
      appendToastMessage(FORM_DICTIONARY.SUCCESS, 'success');
    };

    return {
      togglePopOver,
      loginPopOverRef,
      state,
      setErrorWrapper,
      logOut,
      hasError
    };
  }
});
