import state from '@/store/modules/neural/state';
import actions from '@/store/modules/neural/actions';
import mutations from '@/store/modules/neural/mutations';
import { Module } from 'vuex';
import type { TRootState } from '@/store/state';
import { TNeuralState } from '@/store/modules/neural/types';

const searchModule: Module<TNeuralState, TRootState> = {
  state,
  actions,
  mutations,
  namespaced: true
};

export default searchModule;
