export const MODULES_NAMES = {
  MODELS: 'models',
  USERS: 'users',
  TOASTER: 'toaster',
  SEARCH: 'search',
  PAGINATOR: 'paginator',
  REQUEST: 'request',
  STATISTICS: 'statistics',
  NEURAL: 'neural'
};
