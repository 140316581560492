import { Roles } from '@/types/roles';
import type { TRole, TUser } from '@/store/modules/users/state';
import { RegisteredModelTypes } from '@/types/modelType';
import { uniq } from 'lodash';

export const hasAccess = (userPreferredRmts: RegisteredModelTypes[], listRmts: RegisteredModelTypes[]) => {
  return listRmts.some((item: RegisteredModelTypes) => userPreferredRmts?.includes(item));
};

export const isAdmin = (user: TUser) => {
  return user?.roles?.some((item: TRole) => item.roleId === Roles.ADMIN);
};

export const isLeader = (user: TUser) => {
  return user.roles?.some((item: TRole) => item.roleId === Roles.SUPERVISOR);
};

export const isExpertOnly = (user: TUser) => {
  return (
    user.roles?.some((item: TRole) => item.roleId === Roles.EXPERT) &&
    !user.roles?.some((item: TRole) => item.roleId === Roles.ADMIN) &&
    !user.roles?.some((item: TRole) => item.roleId === Roles.SUPERVISOR)
  );
};

export const isInternalUser = (user: TUser) => {
  return user.roles?.some((item: TRole) => (item.roleId === Roles.INTERNAL_USER || item.roleId === Roles.INTERNAL_ADMIN));
};

export const isInternalAdmin = (user: TUser) => {
  return user.roles?.some((item: TRole) => item.roleId === Roles.INTERNAL_ADMIN);
};

export const isLeaderWithAccess = (user: TUser, listRmts: RegisteredModelTypes[]) => {
  const leaderRole = user.roles?.find((item: TRole) => item.roleId === Roles.SUPERVISOR);
  return hasAccess(leaderRole?.preferredRmts || [], listRmts);
};

export const isExpertWithAccess = (user: TUser, listRmts: RegisteredModelTypes[]) => {
  const leaderRole = user.roles?.find((item: TRole) => item.roleId === Roles.EXPERT);
  return hasAccess(leaderRole?.preferredRmts || [], listRmts);
};

export const hasRmtsAccess = (user: TUser, listRmts: RegisteredModelTypes[]) => {
  let allUserRmts = user.roles!.reduce((prev: RegisteredModelTypes[], curr: TRole) => prev.concat(curr.preferredRmts!), []);
  allUserRmts = uniq(allUserRmts);
  return hasAccess(allUserRmts, listRmts);
};
