import { USERS_ACTIONS_TYPE } from '@/store/names/action.name';
import { UserHttpService } from '@/services/usersService';
import { ActionContext } from 'vuex';
import type { TUsersState } from '@/store/modules/users/state';
import { USERS_MUTATIONS_TYPE } from '@/store/names/mutations.names';

const userHttpService = new UserHttpService();

const actions = {
  async [USERS_ACTIONS_TYPE.FETCH_LOG_IN]({ dispatch }: ActionContext<TUsersState, {}>, payload: object): Promise<void> {
    const { data } = await userHttpService.logIn(payload);
    localStorage.setItem('JWT', data.token);
    await dispatch(`${USERS_ACTIONS_TYPE.FETCH_CURRENT_USER}`);
  },

  async [USERS_ACTIONS_TYPE.FETCH_CURRENT_USER]({ commit }: ActionContext<TUsersState, {}>): Promise<void> {
    const { data } = await userHttpService.getCurrentUser();
    commit(USERS_MUTATIONS_TYPE.SET_CURRENT_USER, data);
  },

  async [USERS_ACTIONS_TYPE.LOG_OUT]({ commit }: ActionContext<TUsersState, {}>): Promise<void> {
    localStorage.removeItem('JWT');
    commit(USERS_MUTATIONS_TYPE.SET_CURRENT_USER, {});
  }
};

export default actions;
