<template>
  <transition :name="animateName">
    <div
      v-if="isVisible"
      class="ui-dropdown-menu"
    >
      <slot name="content">
      </slot>
    </div>
  </transition>
</template>

<script src="./BaseDropdownMenu.ts" lang="ts"/>
<style src="./BaseDropdownMenu.scss" lang="scss" />
