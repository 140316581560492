import axios, { AxiosRequestConfig } from 'axios';
import { AbortError } from '@/services/utils/errors/abortError';
import { ErrorStatus } from '@/services/utils/errors/errorType';

export const SERVER_URL = process.env.VUE_APP_BACKEND_BASE_URL;
// export const SERVER_URL = 'https://acef-94-140-240-79.eu.ngrok.io';
export const BASE_URL = '/rest';
export const API_VERSION = '/v1';
export const FULL_PATH = SERVER_URL + BASE_URL + API_VERSION;

const API = axios.create({
  baseURL: '/'
});

API.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const errorStatus = error?.response?.data?.status || error?.response?.status;
    switch (errorStatus) {
      case ErrorStatus.NOT_AUTH: {
        localStorage.removeItem('JWT');
        break;
      }
    }
    if (error.__CANCEL__) {
      return Promise.reject(new AbortError());
    } else {
      return Promise.reject(error);
    }
  }
);

API.interceptors.request.use((config: AxiosRequestConfig) => {
  config.url = encodeURI(config.url!);
  const jwtToken = `Bearer ${localStorage.getItem('JWT')}`;
  localStorage.getItem('JWT') && (config.headers.Authorization = jwtToken);
  // config.headers['X-Forwarded-Groups'] = 'internal_user';
  // config.headers['X-Forwarded-User'] = 'AndrewInternal1';
  return config;
});

export default API;
