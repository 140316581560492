import { ROOT_ACTIONS_TYPE } from '@/store/names/action.name';
import { ActionContext } from 'vuex';
import { AppHttpService } from '@/services/appService';
import { ROOT_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import type { TRootState } from '@/store/state';

const appHttpService = new AppHttpService();

const actions = {
  async [ROOT_ACTIONS_TYPE.FETCH_APP_VERSION]({ commit }: ActionContext<TRootState, {}>): Promise<void> {
    const { data } = await appHttpService.getVersion();
    commit(`${ROOT_MUTATIONS_TYPE.SET_APP_VERSION}`, data);
  },

  async [ROOT_ACTIONS_TYPE.FETCH_APP_VERSION]({ commit }: ActionContext<TRootState, {}>): Promise<void> {
    const { data } = await appHttpService.getVersion();
    commit(`${ROOT_MUTATIONS_TYPE.SET_APP_VERSION}`, data);
  }
};

export default actions;
