import type { TUser, TUsersState } from '@/store/modules/users/state';
import { USERS_MUTATIONS_TYPE } from '@/store/names/mutations.names';

const mutations = {
  [USERS_MUTATIONS_TYPE.SET_CURRENT_USER](state: TUsersState, payload: TUser) {
    state.user = payload;
  }
};

export default mutations;
