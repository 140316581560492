<template>
  <Button
    :label="label"
    :disabled="disabled"
    :class="`${outlined && 'p-button-outlined'} ui-button ${className}`"
    :icon="primeIcon && `pi ${primeIcon}`"
    :iconPos="iconPosition"
    :type="type"
    @click="clickHandler()"
  ><slot></slot></Button>
</template>

<script src="./BaseButton.ts" lang="ts"/>
<style src="./BaseButton.scss" lang="scss"/>
