<template>
  <div class="p-field ui-input-field">
    <label
      :class="`${disabled && 'ui-disabled' } ${!!errorMessage && 'ui-error-label' }`"
      :for='`uid${name}`'
    >
      {{ label }}
    </label>
    <Dropdown
      :class="`${!!errorMessage && 'ui-error-input'} ui-dropdown ${className}`"
      :disabled="disabled"
      :modelValue="inputValue"
      :options="value"
      @update:modelValue="changeValue($event)"
      :placeholder="placeholder"
      optionLabel="name"
      :filter="hasFilter"
      :loading="loading"
      appendTo="self"
      :showClear="showClear"
      @change="change"
    />
    <BaseInlineMessage
      v-if="!!errorMessage"
      :message-text="errorMessage"
    />
  </div>
</template>

<script src="./BaseDropdown.ts" lang="ts"/>
<style src="./BaseDropdown.scss" lang="scss" />
