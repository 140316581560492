export const createDownload = (data: string, fileName: string) => {
  const blob = new Blob([data]);
  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
  downloadLink.setAttribute('download', fileName);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
