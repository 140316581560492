import { defineComponent, ref } from 'vue';
import { useField } from 'vee-validate';
import type { HTMLInputEvent } from '@/types/eventTypes';
import BaseInlineMessage from '@/components/BaseComponents/BaseInlineMessage/BaseInlineMessage.vue';

export default defineComponent({
  name: 'BaseUploadFile',

  components: {
    BaseInlineMessage
  },

  props: {
    label: String,
    value: {
      type: String
    },
    name: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const filewRef = ref();
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange
    } = useField(props.name, undefined, { initialValue: props.value });

    const customChange = (e: HTMLInputEvent) => {
      handleChange(e?.target?.files?.[0]);
    };

    const click = () => {
      filewRef.value.click();
    };

    return {
      filewRef,
      click,
      inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      customChange
    };
  }
});
