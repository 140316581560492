import type { TModelData } from '@/types/modelType';
import type { TAuthor } from '@/types/authorType';

export type TRequestData = {
  id?: number;
  models?: TModelData[];
  patentId?: string | null;
  requestId?: string | null;
  requester?: null | TAuthor;
}

export type TRequestState = {
  requestData: TRequestData;
  isLoadingRequest: boolean;
}

const state: TRequestState = {
  requestData: {},
  isLoadingRequest: true
};

export default state;
