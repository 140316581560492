import { PAGINATOR_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { ShowType } from '@/store/modules/paginator/state';
import type { TPaginator, TPaginatorState } from '@/store/modules/paginator/state';

type TPagFilters = {
  paginator: TPaginator;
}

const mutations = {
  [PAGINATOR_MUTATIONS_TYPE.SET_SHOW_TYPE](state: TPaginatorState, payload: ShowType) {
    state.showType = payload;
  },

  [PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR](state: TPaginatorState, payload: TPaginator) {
    state.paginator = payload;
  },

  [PAGINATOR_MUTATIONS_TYPE.SET_INITIAL_PAGINATOR](state: TPaginatorState, payload: TPagFilters) {
    state.paginator = payload.paginator;
  }
};
export default mutations;
