import type { TDropdownValue } from '@/types/dropdownTypes';
import { getValueWithZero } from '@/components/Model/utils/getValueWithZero';

export const setArrWithIndex = (arr: TDropdownValue[]) => {
  return arr.map((item: TDropdownValue) => {
    if (item?.classIndex) {
      item.name = `${getValueWithZero(item.classIndex)} ${item.name}`;
    }
    return item;
  });
};
