import { Roles } from '@/types/roles';
import { RegisteredModelTypes } from '@/types/modelType';

export type TUsersState = {
  user: TUser;
}

export type TRole = {
  roleId?: Roles;
  roleName?: string;
  preferredRmts?: RegisteredModelTypes[];
}

export type TUser = {
  id?: number;
  login?: string;
  roles?: TRole[];
};

const state: TUsersState = {
  user: {}
};

export default state;
