import { defineComponent, PropType, watch } from 'vue';
import Dropdown from 'primevue/dropdown';
import { useField } from 'vee-validate';
import BaseInlineMessage from '@/components/BaseComponents/BaseInlineMessage/BaseInlineMessage.vue';

export default defineComponent({
  name: 'BaseDropdown',

  components: {
    Dropdown,
    BaseInlineMessage
  },

  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: Array as PropType<object[]>,
      default: []
    },
    propValue: {
      type: Object
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean
    },
    hasFilter: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showClear: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    prevented: {
      type: Boolean,
      default: false
    }
  },

  emits: ['input', 'change'],

  setup(props, { emit }) {
    const {
      errorMessage,
      value: inputValue,
      handleChange
    } = useField(props.name, undefined, { initialValue: props.propValue });

    const changeValue = (value: any) => {
      if (props.prevented) return;
      handleChange(value);
      emit('input', value);
    };

    watch(() => props.propValue, () => {
      handleChange(props.propValue);
    });

    const change = (e: any) => {
      emit('change', e);
    };

    return {
      change,
      changeValue,
      errorMessage,
      inputValue,
      handleChange
    };
  }
});
