import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
import BaseDropdown from '@/components/BaseComponents/BaseDropdown/BaseDropdown.vue';
import { ModelsHttpService } from '@/services/modelsService';
import { MkpoTypes, MkpoValues } from '@/types/mkpoTypes';
import type { TDropdownValues } from '@/types/dropdownTypes';
import { setArrWithIndex } from '@/components/Forms/MkpoDropdownGroup/utils/setArrWithIndex';

export default defineComponent({
  name: 'FormUploadModel',

  components: {
    BaseDropdown
  },

  props: {
    values: {
      type: Object as PropType<MkpoValues>,
      required: true
    },
    possibleValuesProp: {
      type: Object as PropType<TDropdownValues>
    },
    isWatcherStart: {
      type: Boolean,
      default: true
    }
  },

  emits: ['changed'],

  setup(props, { emit }) {
    const isLoading = ref({
      lvl1: true,
      lvl2: false,
      lvl3: false
    });
    const disabled = ref({
      lvl1: true,
      lvl2: true,
      lvl3: true
    });
    const possibleValues = ref<TDropdownValues>({
      lvl1: [],
      lvl2: [],
      lvl3: []
    });
    const modelsHttpService = new ModelsHttpService();

    const getMkpoList = async(level: string, parentId?: number) => {
      return await modelsHttpService.getMkpoList(level, parentId);
    };

    onMounted(async() => {
      const { data } = await getMkpoList(MkpoTypes.LVL1);
      possibleValues.value.lvl1 = setArrWithIndex(data.content);
      isLoading.value.lvl1 = false;
    });

    watch(() => props.possibleValuesProp, async() => {
      if (props.possibleValuesProp?.lvl2) {
        possibleValues.value.lvl2 = props.possibleValuesProp?.lvl2;
        disabled.value.lvl2 = false;
        disabled.value.lvl3 = true;
      }
      if (props.possibleValuesProp?.lvl3) {
        possibleValues.value.lvl3 = props.possibleValuesProp?.lvl3;
        disabled.value.lvl2 = false;
        disabled.value.lvl3 = false;
      }
    });

    watch(() => props.values.mkpoClassGrandParent, async() => {
      if (props.isWatcherStart) {
        isLoading.value.lvl2 = true;
        disabled.value.lvl2 = !props.values.mkpoClassGrandParent;
        emit('changed', ['mkpoClassParent', 'mkpoClassId']);
        const { data } = await getMkpoList(MkpoTypes.LVL2, props.values.mkpoClassGrandParent?.id);
        possibleValues.value.lvl2 = setArrWithIndex(data.content);
        isLoading.value.lvl2 = false;
      }
    });

    watch(() => props.values.mkpoClassParent, async() => {
      if (props.isWatcherStart) {
        isLoading.value.lvl3 = true;
        disabled.value.lvl3 = !props.values.mkpoClassParent;
        emit('changed', ['mkpoClassId']);
        const { data } = await getMkpoList(MkpoTypes.LVL3, props.values.mkpoClassParent?.id);
        possibleValues.value.lvl3 = setArrWithIndex(data.content);
        isLoading.value.lvl3 = false;
      }
    });

    return {
      isLoading,
      disabled,
      possibleValues
    };
  }
});
