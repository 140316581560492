import { createStore, createLogger } from 'vuex';
import state, { TRootState } from './state';
import mutations from './mutations';
import actions from './actions';
import models from '@/store/modules/models';
import users from '@/store/modules/users';
import toaster from '@/store/modules/toaster';
import search from '@/store/modules/search';
import paginator from '@/store/modules/paginator';
import request from '@/store/modules/request';
import statistic from '@/store/modules/statistic';
import neural from '@/store/modules/neural';
import { MODULES_NAMES } from '@/store/names/modules.name';
import saveFilters from '@/store/saveToQueryPlugins/saveFiltersToQueryPlugin';
import savePaginator from '@/store/saveToQueryPlugins/savePaginatorToQueryPlugin';

const store = createStore<TRootState>({
  plugins: process.env.NODE_ENV === 'production'
    ? [saveFilters, savePaginator]
    : [createLogger(), saveFilters, savePaginator],
  devtools: true,
  state,
  mutations,
  actions,
  modules: {
    [MODULES_NAMES.MODELS]: models,
    [MODULES_NAMES.USERS]: users,
    [MODULES_NAMES.TOASTER]: toaster,
    [MODULES_NAMES.SEARCH]: search,
    [MODULES_NAMES.PAGINATOR]: paginator,
    [MODULES_NAMES.REQUEST]: request,
    [MODULES_NAMES.STATISTICS]: statistic,
    [MODULES_NAMES.NEURAL]: neural
  }
});

export default store;
