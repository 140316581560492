<template>
  <div id="app-content">
    <NavBar/>
    <div class="content">
      <router-view/>
    </div>
    <BaseToaster />
  </div>
</template>

<script>
import { onMounted } from 'vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import BaseToaster from '@/components/BaseComponents/BaseToaster/BaseToaster.vue';
import { useStore } from 'vuex';
import { ROOT_ACTIONS_TYPE } from '@/store/names/action.name';
import appendToastMessage from '@/utils/appendToastMessage';
import DICTIONARY from '@/dictionaries/validation/dictionary.json';

export default {
  name: 'App',

  components: {
    NavBar,
    BaseToaster
  },

  setup() {
    const { dispatch } = useStore();

    onMounted(async() => {
      try {
        await dispatch(`${ROOT_ACTIONS_TYPE.FETCH_APP_VERSION}`);
      } catch (err) {
        appendToastMessage(DICTIONARY.SERVER_ERROR);
      }
    });
  }
};
</script>
