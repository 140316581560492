import { MODELS_MUTATIONS_TYPE, PAGINATOR_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { MODELS_ACTIONS_TYPE } from '@/store/names/action.name';
import type { TModelsState, TMeshState, TFilters } from '@/store/modules/models/state';
import type { ActionContext } from 'vuex';
import { ModelsHttpService } from '@/services/modelsService';
import store from '@/store';
import { RegisteredModelTypes } from '@/types/modelType';
import { createDownload } from '@/store/modules/models/utils/createDownload';
import type { TPostRequestParams } from '@/types/postRequestType';
// import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
// import { NotAvailableError } from '@/services/utils/errors/notAvailableError';
import { MODULES_NAMES } from '@/store/names/modules.name';
import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';
import { setMapMeshState } from '@/components/Model/utils/setMeshState';

const modelsHttpService = new ModelsHttpService();

export const setMeshStateFromDetailList = (detailList: TMeshState[], partUuid?: string | boolean): TMeshState[] => {
  return detailList.map((item: TMeshState) => {
    return {
      ...item,
      isVisible: !partUuid,
      isOpen: true,
      name: item.name.replace(/ +/g, ' ').trim(),
      children: item.children.length ? setMeshStateFromDetailList(item.children, partUuid) : item.children
    };
  });
};

const actions = {
  async [MODELS_ACTIONS_TYPE.FETCH_MODELS_LIST]({ commit }: ActionContext<TModelsState, {}>, modelTypes: RegisteredModelTypes[]): Promise<void> {
    commit(MODELS_MUTATIONS_TYPE.SET_IS_LOADING_LIST, true);
    try {
      const pag = store.state.paginator?.paginator;
      const filtersState = store.state.models?.filters;
      const params: TPostRequestParams = {
        combinedRequestIdAndPatentId: true,
        requestIdPart: filtersState?.query ? filtersState?.query : '',
        startSearchUploadDate: filtersState?.date ? filtersState?.date[0] : null,
        endSearchUploadDate: filtersState?.date ? filtersState?.date[1] : null,
        registeredModelTypeIds: modelTypes,
        mkpoClassId: filtersState?.mkpoClassId ? filtersState?.mkpoClassId : null
      };
      const { data } = await modelsHttpService.getSearchedModels(pag, params);
      commit(MODELS_MUTATIONS_TYPE.SET_MODELS_LIST, data);
    } finally {
      commit(MODELS_MUTATIONS_TYPE.SET_IS_LOADING_LIST, false);
    }
  },

  async [MODELS_ACTIONS_TYPE.FETCH_MODEL_INFO](
    { commit, dispatch, state }: ActionContext<TModelsState, {}>,
    { uuid, partUuid }: { uuid: string; partUuid?: string }
  ): Promise<void> {
    commit(MODELS_MUTATIONS_TYPE.SET_IS_LOADING_MODEL, true);
    const { data } = await modelsHttpService.getModelInfo(uuid);
    // if (data.status !== ModelStatus.CONVERTED) {
    //   throw new NotAvailableError(FORM_DICTIONARY.MODEL_NOT_AVAILABLE);
    // }
    await dispatch(MODELS_ACTIONS_TYPE.FETCH_MODEL_SCREENSHOTS, uuid);
    commit(MODELS_MUTATIONS_TYPE.SET_MODEL_INFO, data);
    const meshState = setMeshStateFromDetailList(data.modelPartList, partUuid);
    commit(MODELS_MUTATIONS_TYPE.SET_MESH_STATE, meshState);
    commit(MODELS_MUTATIONS_TYPE.SET_MAP_MESH_STATE, setMapMeshState(state.meshState, state.searchMesh));
    commit(MODELS_MUTATIONS_TYPE.SET_IS_LOADING_MODEL, false);
  },

  async [MODELS_ACTIONS_TYPE.DELETE_MODEL]({ dispatch, commit }: ActionContext<TModelsState, {}>, { id, modelType }: { id: number; modelType: RegisteredModelTypes[] }): Promise<void> {
    commit(MODELS_MUTATIONS_TYPE.SET_IS_LOADING_LIST, true);
    await modelsHttpService.deleteModel(id);
    await dispatch(MODELS_ACTIONS_TYPE.FETCH_MODELS_LIST, modelType);
  },

  async [MODELS_ACTIONS_TYPE.DOWNLOAD_3DPDF](_: unknown, uuid: string): Promise<void> {
    const { data } = await modelsHttpService.download3dpdf(uuid);
    createDownload(data, `${uuid}.pdf`);
  },

  async [MODELS_ACTIONS_TYPE.DOWNLOAD_FILE](_: unknown, { uuid, fileName }: { uuid: string; fileName: string }): Promise<void> {
    const { data } = await modelsHttpService.downloadFile(uuid);
    createDownload(data, fileName);
  },

  async [MODELS_ACTIONS_TYPE.FETCH_MODEL_SCREENSHOTS]({ commit }: ActionContext<TModelsState, {}>, uuid: string): Promise<void> {
    const { data } = await modelsHttpService.getModelScreenshots(uuid);
    commit(MODELS_MUTATIONS_TYPE.SET_MODEL_SCREENSHOTS, data);
  },

  async [MODELS_ACTIONS_TYPE.GET_MODEL_SEARCH_HISTORY]({ commit }: ActionContext<TModelsState, {}>, uuid: string): Promise<void> {
    const { data } = await modelsHttpService.getModelHistorySearch(uuid);
    commit(MODELS_MUTATIONS_TYPE.SET_MODEL_SEARCH_HISTORY, data);
  },

  async [MODELS_ACTIONS_TYPE.SET_FILTERS]({ commit }: ActionContext<TModelsState, {}>, filters: TFilters): Promise<void> {
    const pagSize = store.state.paginator?.paginator.size || defaultPaginatorSettings.size;
    store.commit(`${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`, {
      page: defaultPaginatorSettings.first,
      size: pagSize
    });
    commit(MODELS_MUTATIONS_TYPE.SET_FILTERS, filters);
  }
};

export default actions;
