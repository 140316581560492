import { Store } from 'vuex';
import type { TRootState } from '@/store/state';
import { MODELS_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { MODULES_NAMES } from '@/store/names/modules.name';
import router from '@/router';
import ROUTE_NAMES from '@/router/route.names.json';

const saveFilters = (store: Store<TRootState>) => {
  store.subscribe((mutation, state) => {
    if (mutation.type === `${MODULES_NAMES.MODELS}/${MODELS_MUTATIONS_TYPE.SET_FILTERS}`) {
      const current = router.currentRoute.value.name || ROUTE_NAMES.PAGE_INDUSTRIAL_LIST;
      const filters = state.models?.filters;
      const paginator = state.paginator?.paginator;
      router.replace({
        name: current,
        query: {
          ...router.currentRoute.value.query,
          query: filters?.query,
          date: filters?.date,
          mkpoClassId: filters?.mkpoClassId,
          inventionFilter: filters?.inventionFilter,
          size: paginator?.size,
          page: paginator?.page
        }
      });
    }
  });
};

export default saveFilters;
