export enum MkpoTypes {
  'LVL1' = '1',
  'LVL2' = '2',
  'LVL3' = '3'
}

export type TMkpoClass = {
  id: number;
  level: number;
  name: string;
  classIndex?: number;
}

export type MkpoValues = {
  mkpoClassGrandParent: TMkpoClass;
  mkpoClassParent: TMkpoClass;
  mkpoClassId: TMkpoClass;
}
