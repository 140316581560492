import { HttpService } from '@/services/httpService';
import { TPaginator } from '@/store/modules/paginator/state';
import { TSaveNetworkParams, TTrainNetworkParams } from '@/store/modules/neural/types';

export class NeuralHttpService {
  private httpService: HttpService = new HttpService();

  async saveNewDataset(file: any, name: string) {
    const axiosConfig = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    return await this.httpService.post(`/neuralnet/dataset?name=${name}`, file, axiosConfig);
  }

  async getDatasetList(pag: TPaginator | undefined) {
    return await this.httpService.get(`/neuralnet/datasets?size=${pag?.size}&page=${pag?.page}`);
  }

  async deleteDataset(params: number) {
    return await this.httpService.delete(`/neuralnet/dataset?id=${params}`);
  }

  async saveNewNeural(params: TSaveNetworkParams) {
    return await this.httpService.post(`/neuralnet/network/?name=${params.name}`, params);
  }

  async getNeuralList(pag: TPaginator | undefined) {
    return await this.httpService.get(`/neuralnet/networks?size=${pag?.size}&page=${pag?.page}&sort=id,desc`);
  }

  async activateNeural(params: number) {
    return await this.httpService.put(`/neuralnet/network/activate?networkId=${params}`);
  }

  async trainNeural(params: TTrainNetworkParams) {
    return await this.httpService.post(`/neuralnet/network/train?networkId=${params.networkId}&datasetId=${params.datasetId}`);
  }

  async deleteNeural(params: number) {
    return await this.httpService.delete(`/neuralnet/network?id=${params}`);
  }

  async getShortNeuralList() {
    return await this.httpService.get('/neuralnet/networks/trained-short');
  }

  async getShortDatasetList() {
    return await this.httpService.get('/neuralnet/datasets/short');
  }

  async getActiveNeural() {
    return await this.httpService.get('/neuralnet/networks/active');
  }
}
