import { ModelFileFormats } from '@/types/modelType';

type ModelFormat = {
  step: {value: number; label: string};
  stp: {value: number; label: string};
  obj: {value: number; label: string};
  stl: {value: number; label: string};
  u3d: {value: number; label: string};
  prc: {value: number; label: string};
  mol: {value: number; label: string};
  mol2: {value: number; label: string};
  cdx: {value: number; label: string};
}

export const MODEL_FORMAT: ModelFormat = {
  step: { value: ModelFileFormats.STEP, label: 'step' },
  stp: { value: ModelFileFormats.STP, label: 'stp' },
  obj: { value: ModelFileFormats.OBJ, label: 'obj' },
  stl: { value: ModelFileFormats.STL, label: 'stl' },
  u3d: { value: ModelFileFormats.U3D, label: 'u3d' },
  prc: { value: ModelFileFormats.PRC, label: 'prc' },
  mol: { value: ModelFileFormats.MOL, label: 'mol' },
  mol2: { value: ModelFileFormats.MOL2, label: 'mol2' },
  cdx: { value: ModelFileFormats.CDX, label: 'cdx' }
};
