import { REQUEST_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import type { TRequestData, TRequestState } from '@/store/modules/request/state';

const mutations = {
  [REQUEST_MUTATIONS_TYPE.SET_REQUEST_DATA](state: TRequestState, payload: TRequestData) {
    state.requestData = payload;
  },

  [REQUEST_MUTATIONS_TYPE.SET_IS_LOADING_REQUEST](state: TRequestState, payload: boolean) {
    state.isLoadingRequest = payload;
  }
};

export default mutations;
