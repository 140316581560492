import { HttpService } from '@/services/httpService';
import type { TPostRequestParams } from '@/types/postRequestType';
import axios from 'axios';
import { maxListSize } from '@/services/constants';
import { checkParentId } from '@/services/utils/hasParentId';
import type { TPaginator } from '@/store/modules/paginator/state';

const CancelToken = axios.CancelToken;
let cancel: Function;

export class ModelsHttpService {
  private httpService: HttpService = new HttpService();

  async getSearchedModels(pag: TPaginator | undefined, params: TPostRequestParams) {
    if (cancel !== undefined) {
      cancel();
    }
    const axiosConfig = {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    };
    return await this.httpService.post(`/models?size=${pag?.size}&page=${pag?.page}&sort=id,desc`, params, axiosConfig);
  }

  async getModelInfo(uuid: string) {
    return await this.httpService.get(`/model/uuid/${uuid}`);
  }

  async uploadModel(params: object) {
    const axiosConfig = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    return await this.httpService.post('/model/file', params, axiosConfig);
  }

  async uploadScreenshot(uuid = '', params: object) {
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        accept: '*/*'
      }
    };
    return await this.httpService.post(`/model/uuid/${uuid}/screenshot`, params, axiosConfig);
  }

  async download3dpdf(uuid: string) {
    return await this.httpService.get(`/model/uuid/${uuid}/3dpdf`, { responseType: 'blob' });
  }

  async downloadFile(uuid: string) {
    return await this.httpService.get(`/model/uuid/${uuid}/download`, { responseType: 'blob' });
  }

  async getModelScreenshots(uuid: string) {
    return await this.httpService.get(`/model/uuid/${uuid}/screenshots`);
  }

  async getMkpoList(level: string, parentId?: number) {
    return await this.httpService.get(`/mkpo/suggest/${level}?size=${maxListSize}${checkParentId(parentId)}`);
  }

  async getMkpoValuesForFilter(mkpoClassId: number) {
    return await this.httpService.get(`/mkpo/branch/${mkpoClassId}`);
  }

  async getModelHistorySearch(uuid: string) {
    return await this.httpService.get(`/compare/tasks?modelUuid=${uuid}&size=${maxListSize}`);
  }

  async restartPipeline(id: number) {
    return await this.httpService.get(`/model/${id}/restart`);
  }

  async deleteModel(id: number) {
    return await this.httpService.delete(`/model/${id}`);
  }
}
