import { defineComponent, PropType } from 'vue';
import { useField } from 'vee-validate';
import BaseInlineMessage from '@/components/BaseComponents/BaseInlineMessage/BaseInlineMessage.vue';

type TRadioGroup = {
  [index: number]: {
    value: number | string;
    label: string;
  };
}
export default defineComponent({
  name: 'BaseRadioGroup',

  components: {
    BaseInlineMessage
  },

  props: {
    label: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    data: {
      type: Array as PropType<TRadioGroup>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const { handleChange, value, errorMessage } = useField(props.name, undefined, {
      type: 'radio',
      initialValue: props.data[0]?.value
    });

    return {
      errorMessage,
      handleChange,
      value
    };
  }
});
