import { RegisteredModelTypes } from '@/types/modelType';
import { NavigationGuardNext } from 'vue-router';
import { isAdmin, isExpertOnly, isInternalUser, isLeaderWithAccess } from '@/utils/rolesUtils';
import type { TRole, TUser } from '@/store/modules/users/state';
import ROUTE_NAMES from '@/router/route.names.json';
import { Roles } from '@/types/roles';
import { hasAccess } from '@/services/rolesService';

export const redirectToPermittedList = (user: TUser, listRmts: RegisteredModelTypes[], next: NavigationGuardNext) => {
  if (!user.id) {
    return next({ name: ROUTE_NAMES.PAGE_REQUESTS });
  }

  if (isExpertOnly(user)) {
    return next({ name: ROUTE_NAMES.PAGE_REQUESTS });
  }

  if (isAdmin(user)) {
    return next();
  }

  if (isInternalUser(user)) {
    return next();
  }

  return isLeaderWithAccess(user, listRmts) ? next() : next('/');
};

export const redirectToMainPage = (user: TUser, next: NavigationGuardNext) => {
  if (isAdmin(user)) {
    return next({ name: ROUTE_NAMES.PAGE_STATISTICS });
  }

  if (isInternalUser(user)) {
    return next({ name: ROUTE_NAMES.PAGE_STATISTICS });
  }

  if (isExpertOnly(user)) {
    return next({ name: ROUTE_NAMES.PAGE_REQUESTS });
  }

  const leaderLists = user.roles?.find((item: TRole) => item.roleId === Roles.SUPERVISOR)?.preferredRmts;

  switch (leaderLists?.length ? leaderLists[0] : null) {
    case (RegisteredModelTypes.UTILITY_MODEL || RegisteredModelTypes.INVENTION):
      return next({ name: ROUTE_NAMES.PAGE_INVENTION_LIST });
    case RegisteredModelTypes.INDUSTRIAL_MODEL:
      return next({ name: ROUTE_NAMES.PAGE_INDUSTRIAL_LIST });
    case RegisteredModelTypes.TRADEMARK:
      return next({ name: ROUTE_NAMES.PAGE_TRADEMARK_LIST });
    case RegisteredModelTypes.PARTICLE:
      return next({ name: ROUTE_NAMES.PAGE_PARTICLES_LIST });
    default:
      return next({ name: ROUTE_NAMES.PAGE_INVENTION_LIST });
  }
};

export const statisticGuard = (user: TUser, next: NavigationGuardNext) => {
  if (isAdmin(user)) {
    hasAccess();
    return next();
  }

  if (isInternalUser(user)) {
    return next();
  }

  return next('/404');
};
