<template>
  <BaseDropdown
    showClear
    :loading="isLoading.lvl1"
    has-filter
    label="Класс МКПО"
    name="mkpoClassGrandParent"
    placeholder="Класс МКПО"
    :value="possibleValues.lvl1"
  />
  <BaseDropdown
    showClear
    :loading="isLoading.lvl2"
    has-filter
    label="Подкласс МКПО"
    name="mkpoClassParent"
    placeholder="Подкласс МКПО"
    :value="possibleValues.lvl2"
    :disabled="disabled.lvl2"
  />
  <BaseDropdown
    showClear
    :loading="isLoading.lvl3"
    has-filter
    label="Наименование"
    name="mkpoClassId"
    placeholder="Наименование"
    :value="possibleValues.lvl3"
    :disabled="disabled.lvl3"
  />
</template>

<script src="./MkpoDropdownGroup.ts" lang="ts"/>
<style src="./MkpoDropdownGroup.scss" lang="scss"/>
