import state, { TSearchState } from '@/store/modules/search/state';
import actions from '@/store/modules/search/actions';
import mutations from '@/store/modules/search/mutations';
import { Module } from 'vuex';
import type { TRootState } from '@/store/state';

const searchModule: Module<TSearchState, TRootState> = {
  state,
  actions,
  mutations,
  namespaced: true
};

export default searchModule;
