<template>
  <header class="p-d-flex p-ai-center">
    <div class="header-content p-d-flex p-ai-center p-jc-between">
      <router-link
        class="logo"
        to="/"
      >
        <img alt="logo" src="@/assets/images/logo.svg" />
      </router-link>
      <div class="p-text-left ui-version">
      </div>
      <div class="links">
        <router-link
          v-if="isAdmin(state.users.user) || isInternalUser(state.users.user)"
          :to="{ name: ROUTE_NAMES.PAGE_STATISTICS }"
        >
          Статистика
        </router-link>
        <router-link
          v-if="isAdmin(state.users.user) || isInternalUser(state.users.user)"
          :to="{ name: ROUTE_NAMES.PAGE_NEURAL }"
        >
          Управление нейросетью
        </router-link>
        <ModelListChangeButton v-if="state.users.user.id && !isExpertOnly(state.users.user)" />
        <BaseButton
          class-name="to-request-btn"
          @clicked="pushToRequest()"
          label="Выбор заявки"
        />
        <UploadButton v-if="isAdmin(state.users.user) || isInternalAdmin(state.users.user)" />
        <div v-if="state.users?.user?.login" class="login">{{ state.users?.user?.login }}</div>
        <AuthButton />
      </div>
    </div>
  </header>
</template>

<script src="./NavBar.ts" lang="ts"/>
<style src="./NavBar.scss" lang="scss"/>
