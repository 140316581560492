import { defineComponent, ref } from 'vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BasePopUp from '@/components/BaseComponents/BasePopUp/BasePopUp.vue';
import FormUploadModel from '@/components/Forms/FormUploadModel/FormUploadModel.vue';
import { useRouter } from 'vue-router';
import ROUTE_NAMES from '@/router/route.names.json';
import type { TFormUploadValues } from '@/types/eventTypes';
import { RegisteredModelTypes } from '@/types/modelType';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { MODELS_ACTIONS_TYPE } from '@/store/names/action.name';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'UploadButton',
  components: {
    BaseButton,
    BasePopUp,
    FormUploadModel
  },
  setup() {
    const uploadFilePopUpRef = ref();
    const router = useRouter();
    const { dispatch } = useStore();

    const togglePopUp = () => {
      return uploadFilePopUpRef?.value?.handleModal();
    };

    const modelUploaded = async(values: TFormUploadValues) => {
      let routeName = '';
      const pageTypes: RegisteredModelTypes[] = [];
      if (values.registeredModelTypeId === RegisteredModelTypes.INVENTION || values.registeredModelTypeId === RegisteredModelTypes.UTILITY_MODEL) {
        pageTypes.splice(0, 0, RegisteredModelTypes.INVENTION, RegisteredModelTypes.UTILITY_MODEL);
        routeName = ROUTE_NAMES.PAGE_INVENTION_LIST;
      }
      if (values.registeredModelTypeId === RegisteredModelTypes.TRADEMARK) {
        routeName = ROUTE_NAMES.PAGE_TRADEMARK_LIST;
        pageTypes.push(RegisteredModelTypes.TRADEMARK);
      }
      if (values.registeredModelTypeId === RegisteredModelTypes.INDUSTRIAL_MODEL) {
        routeName = ROUTE_NAMES.PAGE_INDUSTRIAL_LIST;
        pageTypes.push(RegisteredModelTypes.INDUSTRIAL_MODEL);
      }
      if (values.registeredModelTypeId === RegisteredModelTypes.PARTICLE) {
        routeName = ROUTE_NAMES.PAGE_PARTICLES_LIST;
        pageTypes.push(RegisteredModelTypes.PARTICLE);
      }
      await dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.FETCH_MODELS_LIST}`, pageTypes);
      router.push({
        name: routeName
      });
    };

    return {
      togglePopUp,
      modelUploaded,
      uploadFilePopUpRef
    };
  }
});
