import { AxiosError } from 'axios';
import { ErrorStatus } from '@/services/utils/errors/errorType';
import { AbortError } from '@/services/utils/errors/abortError';
import appendToastMessage from '@/utils/appendToastMessage';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';

export const switchError = (error: AxiosError, message?: string) => {
  const errorStatus = error?.response?.data?.status || error?.response?.status;

  if (error.name === AbortError.name) {
    return null;
  }

  switch (errorStatus) {
    case ErrorStatus.NO_PERMISSIONS:
      return appendToastMessage(FORM_DICTIONARY.NO_PERMISSIONS);
    case ErrorStatus.NOT_FOUND:
      return appendToastMessage(message || FORM_DICTIONARY.NOT_FOUND);
    case ErrorStatus.ALREADY_EXIST:
      return appendToastMessage(message || FORM_DICTIONARY.DATASET_EXIST);
    default:
      return appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
  }
};
