import { NEURAL_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { TPageable } from '@/store/typeUtils';
import {
  TDatasetUnit,
  TNeuralState,
  TNeuralUnit,
  TShortDatasetUnit,
  TShortNeuralUnit
} from '@/store/modules/neural/types';
import { TPaginator } from '@/store/modules/paginator/state';

const mutations = {
  [NEURAL_MUTATIONS_TYPE.SET_NEURAL_LIST](state: TNeuralState, payload: TPageable<TNeuralUnit>) {
    state.neuralList = payload;
  },

  [NEURAL_MUTATIONS_TYPE.SET_DATASET_LIST](state: TNeuralState, payload: TPageable<TDatasetUnit>) {
    state.datasetList = payload;
  },

  [NEURAL_MUTATIONS_TYPE.SET_LOADING_NEURAL](state: TNeuralState, payload: boolean) {
    state.isLoadingNeural = payload;
  },

  [NEURAL_MUTATIONS_TYPE.SET_LOADING_DATASET](state: TNeuralState, payload: boolean) {
    state.isLoadingDataset = payload;
  },

  [NEURAL_MUTATIONS_TYPE.SET_SHORT_NEURAL_LIST](state: TNeuralState, payload: TShortNeuralUnit[]) {
    state.shortNeural = payload;
  },

  [NEURAL_MUTATIONS_TYPE.SET_SHORT_DATASET_LIST](state: TNeuralState, payload: TShortDatasetUnit[]) {
    state.shortDataset = payload;
  },

  [NEURAL_MUTATIONS_TYPE.SET_LOADING_SHORT](state: TNeuralState, payload: boolean) {
    state.isLoadingShort = payload;
  },

  [NEURAL_MUTATIONS_TYPE.SET_DATASET_PAGINATOR](state: TNeuralState, payload: TPaginator) {
    state.paginator = payload;
  },

  [NEURAL_MUTATIONS_TYPE.SET_ACTIVE_NEURAL_OPTIONS](state: TNeuralState, payload: TNeuralUnit[]) {
    state.activeNetworks = payload;
  },

  [NEURAL_MUTATIONS_TYPE.SET_ACTIVE_NEURAL_OPTIONS](state: TNeuralState, payload: TNeuralUnit[]) {
    state.activeNetworks = payload;
  },

  [NEURAL_MUTATIONS_TYPE.SET_IS_LOADING_ACTIVE](state: TNeuralState, payload: boolean) {
    state.isLoadingActive = payload;
  }
};

export default mutations;
