import { TNeuralState } from '@/store/modules/neural/types';
import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';

const state: TNeuralState = {
  neuralList: {
    content: []
  },
  datasetList: {
    content: []
  },
  shortNeural: [],
  shortDataset: [],
  isLoadingShort: true,
  isLoadingDataset: true,
  isLoadingNeural: true,
  activeNetworks: [],
  isLoadingActive: true,
  paginator: {
    size: defaultPaginatorSettings.size,
    page: defaultPaginatorSettings.first
  }
};

export default state;
