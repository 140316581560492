<template>
  <BaseButton
    label="Загрузить"
    iconPosition="left"
    primeIcon="pi-upload"
    @clicked="togglePopUp"
  />
  <BasePopUp
    title="Загрузка модели"
    ref="uploadFilePopUpRef"
  >
    <template v-slot:content>
      <FormUploadModel
        @submitted="modelUploaded($event)"
        @closePopup="togglePopUp"
      />
    </template>
  </BasePopUp>
</template>

<script src="./UploadButton.ts" lang="ts"/>
