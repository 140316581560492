import API, { FULL_PATH } from '@/services/apiService';

export class HttpService {
  fullPath: string;

  constructor() {
    this.fullPath = FULL_PATH;
  }

  async get(url: string, options?: object) {
    return await API.get(`${this.fullPath}${url}`, options);
  }

  async post(url: string, params?: object, axiosConfig?: object) {
    return await API.post(`${this.fullPath}${url}`, params, axiosConfig);
  }

  async put(url: string, params?: object) {
    return await API.put(`${this.fullPath}${url}`, params);
  }

  async delete(url: string, params?: object) {
    return await API.delete(`${this.fullPath}${url}`, params);
  }
}
