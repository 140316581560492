<template>
  <teleport to="#app-content">
    <transition name="fade">
      <div
        v-if="isShowModal"
        class="ui-modal-overlay p-jc-center p-ai-center"
        @click.self="handleModal"
      >
        <div :class="`ui-modal ${className}`">
          <div class="modal-header p-d-flex p-jc-between p-ai-center">
            <div class="ui-title-small">{{ title }}</div>
            <button @click='handleModal' class="modal-close-btn">×</button>
          </div>
          <div class="split-line"></div>
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script src="./BasePopUp.ts" lang="ts"/>
<style src="./BasePopUp.scss" lang="scss"/>
