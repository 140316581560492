import defaultPaginatorSettings from '@/dictionaries/defaultPaginatorSettings/defaultPaginatorSettings';

export enum ShowType {
  'TABLE'= 0,
  'CARDS' = 1
}

export type TPaginator = {
  size: number;
  page: number;
}

export type TPaginatorState = {
  paginator: TPaginator;
  showType: ShowType;
}

const state: TPaginatorState = {
  paginator: {
    size: defaultPaginatorSettings.size,
    page: defaultPaginatorSettings.first
  },
  showType: ShowType.CARDS
};

export default state;
