import { Store } from 'vuex';
import type { TRootState } from '@/store/state';
import { PAGINATOR_MUTATIONS_TYPE } from '@/store/names/mutations.names';
import { MODULES_NAMES } from '@/store/names/modules.name';
import router from '@/router';
import ROUTE_NAMES from '@/router/route.names.json';

const savePaginator = (store: Store<TRootState>) => {
  store.subscribe((mutation, state) => {
    if (mutation.type === `${MODULES_NAMES.PAGINATOR}/${PAGINATOR_MUTATIONS_TYPE.SET_PAGINATOR}`) {
      const current = router.currentRoute.value.name || ROUTE_NAMES.PAGE_INDUSTRIAL_LIST;
      const paginator = state.paginator?.paginator;
      router.replace({
        name: current,
        query: {
          ...router.currentRoute.value.query,
          size: paginator?.size,
          page: paginator?.page
        }
      });
    }
  });
};

export default savePaginator;
