import { ModelFileFormats } from '@/types/modelType';

const stringIsNumber = (value: any) => isNaN(Number(value));

export const fileTest = (inputId: string) => {
  const inputSelector = (document.getElementById(inputId) as HTMLInputElement);
  const fileName = inputSelector.files?.length ? inputSelector.files[0]?.name?.toLowerCase() : '';
  let formatArr = Object.values(ModelFileFormats).filter(stringIsNumber);
  formatArr = formatArr.map((item: any) => '\\.' + item.toLowerCase());
  const pattern = (`${formatArr.join('|')}`);
  const allowedExtensions = new RegExp(pattern, 'i');
  return !!allowedExtensions.exec(fileName);
};
