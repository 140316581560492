import type { TModelData, TModelListData } from '@/types/modelType';
import { Roles } from '@/types/roles';
import { ModelStatus, RegisteredModelTypes } from '@/types/modelType';
import type { TAuthor } from '@/types/authorType';

export type TDateFilters = string[] | null;

export type TQueryFilters = string | null;

export type TmkpoClassId = number | null;

export enum InventionFilter {
  'INVENTION_UTILITY' = 0,
  'INVENTION' = 1,
  'UTILITY' = 2,
  'NOTHING' = 3
}

export type TFilters = {
  query: TQueryFilters;
  date: TDateFilters;
  mkpoClassId?: TmkpoClassId;
  inventionFilter: InventionFilter;
}

export type TScreenshots = {
  commentary: string;
  createdAt: number;
  createdBy: {
    id: number;
    login: string;
    role: Roles;
    roleName: string;
  };
  id: number;
}

export type TModelHistoryItem = {
  compareResultsCount: number;
  deleted: boolean | null;
  filters: {
    registeredModelTypeId: RegisteredModelTypes;
    mkpoClassId: number;
  }[];
  id: number;
  model: TModelData;
  progress: number;
  startTime: number;
  status: ModelStatus;
  user: TAuthor;
}

// TODO
export type TModelSearchHistory = {
  content: TModelHistoryItem[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: {
    offset?: number;
    pageNumber?: number;
    pageSize?: number;
    paged?: boolean;
    sort?: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged?: boolean;
  };
  size?: number;
  sort?: {
    empty?: boolean;
    sorted?: boolean;
    unsorted?: boolean;
  };
  totalElements?: number;
  totalPages?: number;
}

export type TMeshState = {
  id: number;
  name: string;
  uuid: string;
  isVisible: boolean;
  isOpen: boolean;
  children: TMeshState[];
}

export type TModelsState = {
  modelsData: TModelListData;
  filters: TFilters;
  isLoadingList: boolean;
  modelInfo: TModelData;
  modelScreenShots: TScreenshots[];
  isLoadingModel: boolean;
  meshState: TMeshState[];
  mapMeshState: {[key: number]: TMeshState};
  searchMesh: string;
  modelSearchHistory: TModelSearchHistory;
}

export const defaultFiltersValue: TFilters = {
  query: null,
  date: null,
  mkpoClassId: null,
  inventionFilter: InventionFilter.INVENTION_UTILITY
};

const state: TModelsState = {
  modelsData: {
    content: []
  },
  filters: defaultFiltersValue,
  isLoadingList: true,
  modelInfo: {},
  modelScreenShots: [],
  modelSearchHistory: {
    content: []
  },
  meshState: [],
  mapMeshState: {},
  searchMesh: '',
  isLoadingModel: true
};

export default state;
