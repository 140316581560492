<template>
  <div id="auth-button-area"></div>
  <div :class="hasError && 'error-ui-overlaypanel'">
    <template v-if="!state.users?.user?.login">
      <BaseButton
        label="Войти"
        outlined
        iconPosition="left"
        primeIcon="pi-sign-in"
        @clicked="togglePopOver"
      />
      <BasePopOver
        :class-name="hasError ? 'error-ui-overlaypanel login-popover' : 'login-popover'"
        appendTo="#auth-button-area"
        ref="loginPopOverRef"
      >
        <template v-slot:popover-content>
          <FormLogin @submitted="togglePopOver" @serverError="setErrorWrapper"/>
        </template>
      </BasePopOver>
    </template>
    <template v-else>
      <BaseButton
        label="Выйти"
        outlined
        iconPosition="left"
        primeIcon="pi-sign-out"
        @clicked="logOut()"
      />
    </template>
  </div>
</template>

<script src="./AuthButton.ts" lang="ts"/>
<style src="./AuthButton.scss" lang="scss"/>
