<template>
  <OverlayPanel
    v-if="isMounted"
    ref="BasePopOverRef"
    :dismissable="false"
    :class="`${className} ui-overlaypanel`"
    :appendTo="appendElementId"
  >
    <slot name="popover-content"></slot>
  </OverlayPanel>
</template>

<script src="./BasePopOver.ts" lang="ts"/>
<style src="./BasePopOver.scss" lang="scss" />
