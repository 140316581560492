import { defineComponent, onUnmounted, ref, watch } from 'vue';

export default defineComponent({
  name: 'BasePopUp',
  props: {
    title: {
      type: String
    },
    className: {
      type: String
    }
  },
  setup() {
    const isShowModal = ref(false);

    const handleModal = () => {
      isShowModal.value = !isShowModal.value;
    };

    watch(() => isShowModal.value, () => {
      const body = document.querySelector('body');
      if (isShowModal.value) {
        // eslint-disable-next-line no-unused-expressions
        body?.classList.add('overflow-body');
      } else {
        // eslint-disable-next-line no-unused-expressions
        body?.classList.remove('overflow-body');
      }
    });

    onUnmounted(() => {
      const body = document.querySelector('body');
      // eslint-disable-next-line no-unused-expressions
      body?.classList.remove('overflow-body');
    });

    return {
      isShowModal,
      handleModal
    };
  }
});
