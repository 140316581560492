import { MODULES_NAMES } from '@/store/names/modules.name';
import { USERS_ACTIONS_TYPE } from '@/store/names/action.name';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import store from '@/store';
// import appendToastMessage from '@/utils/appendToastMessage';
// import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';

const routerGuardsCheckAuth = async(_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const isAuth = !!store.state.users?.user?.login;
  if (isAuth) {
    next();
  } else {
    try {
      await store.dispatch(`${MODULES_NAMES.USERS}/${USERS_ACTIONS_TYPE.FETCH_CURRENT_USER}`);
      next();
    } catch (err) {
      // TODO временно убрал
      // appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
      next();
    }
  }
};

export default routerGuardsCheckAuth;
