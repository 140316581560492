import { RegisteredModelTypes } from '@/types/modelType';

export type TStatisticUnit = {
  errorModelsCount: number;
  patentsCount: number;
  processingModelsCount: number;
  successModelsCount: number;
  requestsCount: number;
  registeredModelType: {
    id: RegisteredModelTypes;
    type: string;
    fullLocalizedName: string;
    shortLocalizedName: string;
  };
}

export type TStatisticState = {
  isLoading: boolean;
  isLoadingPage: boolean;
  content?: {
    [RegisteredModelTypes.INVENTION]?: TStatisticUnit;
    [RegisteredModelTypes.UTILITY_MODEL]?: TStatisticUnit;
    [RegisteredModelTypes.INDUSTRIAL_MODEL]?: TStatisticUnit;
    [RegisteredModelTypes.TRADEMARK]?: TStatisticUnit;
    [RegisteredModelTypes.PARTICLE]?: TStatisticUnit;
    endSearchDate?: string;
    startSearchDate?: string;
  };
  statisticsPage: any;
  filters: {
    query: string;
    type: RegisteredModelTypes[];
    status?: RequestStatus;
  };
  statisticFilters: {
    type: RegisteredModelTypes[];
    dates?: [Date, Date] | null;
  };
}

export enum RequestStatus {
  'PROCESSING' = 'PROCESSING',
  'CONVERTED' = 'SUCCESS',
  'FAILED' = 'FAILED',
  'ALL' = 'ALL'
}

const state: TStatisticState = {
  isLoading: true,
  content: {},
  isLoadingPage: true,
  statisticsPage: {
    content: []
  },
  filters: {
    query: '',
    type: [],
    status: RequestStatus.ALL
  },
  statisticFilters: {
    type: [],
    dates: null
  }
};

export default state;
