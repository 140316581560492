import type { TSearchInfo } from '@/types/serachCompareTypes';
import type { TModelData } from '@/types/modelType';
import type { TRequestData } from '@/store/modules/request/state';
import type { TAuthor } from '@/types/authorType';

export type TReportUnitContent = {
  createdAt: number;
  createdBy: TAuthor;
  distance: number;
  id: number;
  modelFrom: TModelData;
  modelPartFrom: TModelData | null;
  modelPartTo: TModelData | null;
  modelTo: TModelData;
}

export type TReportData = {
  id?: number;
  isFinished?: boolean;
  request?: TRequestData;
};

export type TReportUnitsState = {
  content: TReportUnitContent[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: {
    offset?: number;
    pageNumber?: number;
    pageSize?: number;
    paged?: boolean;
    sort?: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged?: boolean;
  };
  size?: number;
  sort?: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  statistics?: {
    totalModelModel: number;
    totalModelPart: number;
    totalPartModel: number;
    totalPartPart: number;
  };
  totalElements?: number;
  totalPages?: number;
};

export type TSearchState = {
  filters: TReportFilters;
  reportData: TReportData;
  reportUnits: TReportUnitsState;
  searchData: TSearchInfo;
  isLoadingSearch: boolean;
  isLoadingReport: boolean;
  isLoadingReportUnits: boolean;
  searchFilter: TSearchFilter;
  searchResult: TSearchResult;
  isPostingReportUnit: boolean;
}

export type TSearchFilter = {
  showModelModel: boolean;
  showModelPart: boolean;
  showPartModel: boolean;
  showPartPart: boolean;
}

export const defaultSearchFiltersValue: TSearchFilter = {
  showModelModel: true,
  showModelPart: false,
  showPartModel: false,
  showPartPart: false
};

export type TSearchedData = {
  distance: number;
  id: number;
  isAddedToReport: boolean;
  reportUnitId?: number;
  modelPartFrom: TSerachedDetailData;
  modelPartTo: TSerachedDetailData;
  modelFrom: TModelData;
  modelTo: TModelData;
  readable: boolean;
  relevance: any;
}

export type TSerachedDetailData = {
  children: TSerachedDetailData[];
  id: number;
  name: string;
  uuid: string;
}

export type TSearchResult = {
  content?: TSearchedData[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: {
    offset?: number;
    pageNumber?: number;
    pageSize?: number;
    paged?: boolean;
    sort?: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged?: boolean;
  };
  size?: number;
  sort?: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  totalElements?: number;
  totalPages?: number;
}

export type TReportFilters = {
  date?: [string, string] | null;
  query?: string | null;
}

export enum CompareType {
  ANALYTICAL = 'analytical',
  NEURAL = 'neural'
}

export const defaultReportFiltersValue: TReportFilters = {
  date: null,
  query: null
};

const state: TSearchState = {
  filters: defaultReportFiltersValue,
  reportData: {},
  reportUnits: {
    content: []
  },
  searchData: {},
  searchResult: {},
  searchFilter: defaultSearchFiltersValue,
  isLoadingSearch: true,
  isLoadingReport: true,
  isLoadingReportUnits: true,
  isPostingReportUnit: false
};

export default state;
