import store from '@/store';
import { Roles } from '@/types/roles';

export type TFeatures = {
 [key: string]: {
   access: Roles[],
   rmtAccess?: Roles[]
 }
}

export const features: TFeatures = {
  // adminsFeatures: {
  //   rolesAccess: [Roles.ADMIN]
  // },
  // rmtsFeatures: {
  //   rolesAccess: [Roles.ADMIN, Roles.INTERNAL_ADMIN, Roles.INTERNAL_USER, Roles.SUPERVISOR, Roles.EXPERT]
  // }
  showRequest: {
    access: [Roles.ADMIN, Roles.INTERNAL_ADMIN, Roles.INTERNAL_USER],
    rmtAccess: [Roles.SUPERVISOR, Roles.EXPERT],
  },
  showModel: {
    access: [Roles.ADMIN, Roles.INTERNAL_ADMIN, Roles.INTERNAL_USER, Roles.SUPERVISOR, Roles.EXPERT],
  },
  showReport: {
    access: [Roles.ADMIN, Roles.INTERNAL_ADMIN, Roles.INTERNAL_USER],
    rmtAccess: [Roles.SUPERVISOR, Roles.EXPERT]
  },
  showSearch: {
    access: [Roles.ADMIN, Roles.INTERNAL_ADMIN, Roles.INTERNAL_USER, Roles.SUPERVISOR],
    rmtAccess: [Roles.EXPERT]
  },
  showModelList: {
    access: [Roles.ADMIN, Roles.INTERNAL_ADMIN, Roles.INTERNAL_USER],
    rmtAccess: [Roles.SUPERVISOR]
  },
  showStatistic: {
    access: [Roles.ADMIN, Roles.INTERNAL_ADMIN, Roles.INTERNAL_USER]
  },
  uploadModel: {
    access: [Roles.ADMIN, Roles.INTERNAL_ADMIN]
  },
};

export const hasAccess = (): boolean => {
  const roles = store.state.users?.user.roles;
  console.log(roles);
  return true;
};
