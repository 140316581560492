import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'BaseDropdownMenu',

  props: {
    animateName: {
      type: String
    }
  },

  setup() {
    const isVisible = ref(false);

    const setInVisible = () => {
      isVisible.value = false;
    };

    const setVisible = () => {
      isVisible.value = true;
    };

    return {
      isVisible,
      setVisible,
      setInVisible
    };
  }
});
