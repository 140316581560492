export type TToasterState = {
  messages: TToasterMessage[];
}

export type TToasterMessage = {
  severity?: string;
  content: string;
}

const state: TToasterState = {
  messages: []
};

export default state;
